import axios from "axios";
console.log(sessionStorage.getItem("token"));
export const getAllDevelopers = () =>
  axios.get("/user/view", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
export const getAllDevelopersFilter = (value, experience) =>
  axios.post(
    "/user/view",
    {
      skill: [
        {
          value: value,
          experience: experience,
        },
      ],
    },
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
export const getAllDeveloperskills = () =>
  axios.get("/user/skill", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
export const getAllskilledDevelopers = async (skill) => {
  const skills = [];
  console.log(skill);
  const params = new URLSearchParams();
  console.log(sessionStorage.getItem("token"));
  for (let i = 0; i < skill?.length; i++) {
    params.append("skill[]", skill[i]);
  }
  skill.map((skill) =>
    skills.push({
      value: skill,
    })
  );
  console.log("skills", skills);
  const { data } = await axios.post(
    `/user/view`,
    {
      skill: skills,
    },
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
  console.log(data);
  return data;
};
export const getDeveloperbyId = async (id) => {
  console.log(id);
  const { data } = await axios.get(`/user/view/${id}`, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
  console.log(data);
  return data;
};
export const AddDeveloper = async (data, tags) => {
  const params = new FormData();
  console.log("tags", tags);
  params.append("fullname", data?.fullName);
  params.append("phone", data?.contact);
  params.append("email", data?.email);
  params.append("applyfor", data?.position);
  params.append("level", data?.level);
  params.append("qualification", "BS");
  params.append("specialization", "CS");
  params.append("experience", data?.experience);
  params.append("joining_time", data?.joiningtime);
  params.append("country", "Pakistan");
  params.append("file", data?.file);
  for (let i = 0; i < tags.length; i++) {
    params.append(`skill[${i}][value]`, tags[i].value);
    params.append(`skill[${i}][experience]`, tags[i].experience);
  }

  console.log(data);
  console.log(tags);
  return await axios.post("/user/add", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
export const UpdateDeveloper = async (data, tags, id) => {
  console.log(data);
  console.log(tags);
  const params = new URLSearchParams();

  params.append("fullname", data?.fullName);
  params.append("phone", data?.contact);
  params.append("email", data?.email);
  params.append("applyfor", data?.position);
  params.append("level", data?.level);
  params.append("qualification", "BS");
  params.append("specialization", "CS");
  params.append("experience", data?.experience);
  params.append("joining_time", data?.joiningtime);
  params.append("country", data?.country);
  params.append("file", data?.file);
  for (let i = 0; i < tags.length; i++) {
    params.append(`skill[${i}][value]`, tags[i].skill || tags[i].skill);
    params.append(`skill[${i}][experience]`, tags[i].experience);
  }
  params.append("id", id);
  // params.append("resume_id", data?.resumeid);
  // if (!Object.prototype.toString.call(data?.file) === "[object Object]") {
  //   params.append("file", data?.file);
  // }

  for (let i = 0; i < tags.length; i++) {
    params.append("skill", tags[i]);
  }

  console.log(data);
  console.log(tags);
  return await axios.put("/user/update", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
export const UpdateDeveloperSkills = async (tags, id) => {
  console.log(tags);
  const params = new URLSearchParams();

  for (let i = 0; i < tags.length; i++) {
    params.append(
      `skill[${i}][value]`,
      tags[i].skill ? tags[i].skill : tags[i].value
    );
    params.append(`skill[${i}][experience]`, tags[i].experience);
  }

  // params.append("resume_id", data?.resumeid);
  // if (!Object.prototype.toString.call(data?.file) === "[object Object]") {
  //   params.append("file", data?.file);
  // }

  return await axios.put(`/user/applicant_skill/${id}`, params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
export const UpdateResume = async (data) => {
  console.log(data);
  const params = new FormData();

  params.append("resume_id", data?.resumeid);
  params.append("file", data?.file);

  return await axios.post("/user/Update_applicants_resume", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
export const sendMail = async (data, id) => {
  const params = new URLSearchParams();

  params.append("name", data?.name);
  params.append("phone", data?.phone);
  params.append("email", data?.email);
  params.append("status", "Lead-in");
  params.append("Lead_Type", data?.Lead_Type);
  params.append("id[]", id);

  console.log(data);
  console.log(id);
  return await axios.post("/user/send-email", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};

///DELETE DEVELOPERS

export const deleteDeveloper = async (id) => {
  const params = new URLSearchParams();
  console.log(id);
  // for (let i = 0; i < id?.length; i++) {
  //   params.append("ids[]", id);
  // }
  const value = {
    ids: id?.length > 1 ? id[0] : id,
  };
  console.log(value);
  return await axios.post("/user/delete", value, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};

export const updateStatus = async (data, id) => {
  console.log(data);
  console.log(id);
  const params = new URLSearchParams();

  params.append("id", id);
  params.append("status", data);

  return await axios.patch("/user/update_status", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
export const updateNote = async (data, id) => {
  console.log(data);
  console.log(id);
  const params = new URLSearchParams();

  params.append("id", id);
  params.append("note[]", data);

  return await axios.patch("/user/update_status", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
