import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import "./index.css";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getAllskilledDevelopers, sendMail } from "../../api/developer";
import CircularProgress from "@mui/material/CircularProgress";
import Notification from "../../actions/alert";
import { useNavigate } from "react-router-dom";

const HireDeveloper = () => {
  const navigate = useNavigate();
  const admindata = useSelector((state) => state);
  const isNonMobile = useMediaQuery("(min-width:800px)");
  const [selectedid, setselectedid] = useState("");
  const [hireloading, sethireloading] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    sethireloading(true);
    await sendMail(initialValue, selectedid)
      .then((res) => {
        console.log(res);
        setNotify({
          isOpen: true,
          message: `Invitation Sent Successfully`,
          type: "error",
        });
        sethireloading(false);
        navigate("/SuccessRequest");
      })
      .catch((error) => {
        console.log(error);
        sethireloading(false);
        setNotify({
          isOpen: true,
          message: `${
            error?.response?.data?.error || error?.response?.data?.message || error?.message
          } `,
          type: "error",
        });
      });
  };
  const [initialValue, setinitialValue] = useState({
    name: "",
    email: "",
    phone: "",
    Lead_Type: admindata?.loginadmin?.isauthenticated ? "Manual" : "Website",
    status: "Lead-in",
  });
  const [isLoading, setisLoading] = useState(false);
  const [developersfiltered, setdevelopersfiltered] = useState([]);
  const getDevelopers = () => {
    setisLoading(true);
    getAllskilledDevelopers(admindata?.developer?.selectedSkills)
      .then((res) => {
        setdevelopersfiltered(
          res?.data?.docs.filter((data) => data.status === "verified")
        );
        setisLoading(false);
        console.log(res);
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getDevelopers();
  }, []);
  console.log(developersfiltered);
  return (
    <Box
      m="20px"
      height={admindata?.loginadmin?.isauthenticated ? "80vh" : "90vh"}
      overflow="auto"
      paddingRight="1rem"
    >
      <Header title="Find A Developer" subtitle="Select Developer To Hire" />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            verticalAlign: "middle",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          display="flex"
          flexWrap="wrap"
          gap={5}
          m="20px"
          height="58vh"
          overflow="auto"
          pb="2rem"
        >
          {developersfiltered?.length > 0 ? (
            developersfiltered.map((data, index) => (
              <Card
                key={index}
                className={`${selectedid === data?._id ? "selected" : ""}`}
                variant="outlined"
                sx={{
                  backgroundColor: "#fff",
                  color: `${selectedid === data?._id ? "#fff" : "#222"}`,
                  width: "250px",
                  backgroundColor: `${
                    selectedid === data?._id ? "#1F2A40" : "#fff"
                  }`,
                  boxShadow: `${
                    selectedid === data?._id
                      ? " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, #3da58a 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
                      : "none"
                  }`,
                  height: "250px",
                }}
              >
                {" "}
                <React.Fragment>
                  <CardContent
                    onClick={() => setselectedid(data?._id)}
                    sx={{ cursor: "pointer" }}
                    key={index}
                  >
                    <Typography variant="h4" component="div" className="mb-3">
                      {data?.fullname}{" "}
                    </Typography>
                    {/* <Typography
                    sx={{ mb: 1.5 }}
                    color={selectedid === data?._id ? "#fff" : "#222"}
                  >
                    {data?.email}{" "}
                  </Typography> */}
                    <Box display="flex" gap={1} flexWrap="wrap" mt={3}>
                      {data?.skill?.map((skil) => (
                        <Typography
                          key={data}
                          variant="body2"
                          sx={{
                            backgroundColor: "#1e5245",
                            color: "#fff",
                            borderRadius: "10px",
                            padding: "8px 10px",
                          }}
                        >
                          {skil.value}-{skil?.experience}
                        </Typography>
                      ))}
                    </Box>
                  </CardContent>
                  {/* <CardActions>
                  <Button
                    size="large"
                    onClick={() => window.open(data?.resume, "_blank")}
                  >
                    <span
                      className={
                        selectedid === data?._id ? "text-white" : "text-dark"
                      }
                    >
                      Show&nbsp;CV
                    </span>
                  </Button>
                </CardActions> */}
                </React.Fragment>
              </Card>
            ))
          ) : (
            <Box display="flex" flexDirection="column" gap={4}>
              <small className="text-white text-[15px]">
                No Developer Found! Try other skills to find suitable developer.
              </small>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => navigate("/selectSkills")}
              >
                Go back
              </Button>
            </Box>
          )}
        </Box>
      )}

      {developersfiltered?.length > 0 && (
        <form onSubmit={handleFormSubmit}>
          <Header
            title="Personal Information"
            subtitle="Your Name & Contact Information"
          />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Full Name"
              onChange={(e) => {
                setinitialValue({
                  ...initialValue,
                  name: e.target.value,
                });
              }}
              value={initialValue?.name}
              name="name"
              sx={{ gridColumn: "span 2" }}
              required
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              onChange={(e) => {
                setinitialValue({
                  ...initialValue,
                  email: e.target.value,
                });
              }}
              value={initialValue?.email}
              name="email"
              sx={{ gridColumn: "span 2" }}
              required
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Contact Number"
              onChange={(e) => {
                setinitialValue({
                  ...initialValue,
                  phone: e.target.value,
                });
              }}
              value={initialValue?.phone}
              name="contact"
              sx={{ gridColumn: "span 2" }}
              required
            />
          </Box>
          {hireloading ? (
            <Box display="flex" justifyContent="center" mt="3rem">
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Box display="flex" justifyContent="end" mt="30px" pb="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                disabled={isLoading || selectedid === ""}
              >
                Hire Developer
              </Button>
            </Box>
          )}
        </form>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});

export default HireDeveloper;
