import React, { useState, useEffect } from "react";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { borderRadius, padding } from "@mui/system";
import TagsInput from "../AddDeveloper/tags";
import { tokens } from "../../../theme";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import validator from "validator";
import Notification from "../../../actions/alert";
import {
  Funcauthentication,
  FuncDeleteDev,
} from "../../../reduxtoolkit/developerSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  getDeveloperbyId,
  UpdateDeveloper,
  UpdateDeveloperSkills,
  UpdateResume,
} from "../../../api/developer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeletePopup from "./deletepopup";
import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";

import { countries } from "../../../helpers/countries";

const DeveloperDetails = () => {
  const { id } = useParams();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    dispatch(Funcauthentication(true));
    getDeveloper();
  }, []);
  const getDeveloper = () => {
    setisLoading(true);
    // const data = getDeveloperbyId(id);
    // console.log(data)
    getDeveloperbyId(id)
      .then((res) => {
        console.log(res);
        setinitialValues({
          fullName: res.data.fullname,
          position: res.data.applyfor,
          level: res.data.level,
          joiningtime: res.data.joining_time,
          email: res.data?.email,
          contact: res.data?.phone,
          setValue: res.data?.phone,
          qualification: res.data?.qualification,
          country: res.data?.country,
          specialization: res.data?.specialization,
          tags: res.data?.skill,
          file: res.data?.resume,
          resumeid: res.data?.resume_id,
          _id: res.data?._id,
          experience: res.data?.experience,
        });
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setisLoading(true);
      });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:800px)");

  const admindata = useSelector((state) => state);
  console.log("AddNewDeveloper", admindata);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [initialValues, setinitialValues] = useState({
    fullName: "",
    position: "",
    email: "",
    contact: "",
    country: "",

    qualification: "",
    specialization: "",
    file: null,
    experience: "",
    level: "",
    joiningtime: "",
    tags: [],

    _id: "",
  });

  console.log(initialValues);
  const handleFormSubmit = () => {
    // e.preventDefault();
    setLoading(true);
    console.log(initialValues);
    validator.isEmail(initialValues?.email)
      ? UpdateDeveloper(initialValues, admindata?.developer?.tags, id)
          .then((res) => {
            console.log(res);
            UpdateDeveloperSkills(admindata?.developer?.tags, id);
            console.log(Object.prototype.toString.call(initialValues?.file));
            Object.prototype.toString.call(initialValues?.file) ===
            "[object File]"
              ? updateresume()
              : navigate("/UpdateSuccess");

            // setLoading(false);
          })
          .catch((err) => {
            console.log(err.response);
            setNotify({
              isOpen: true,
              message: `${
                err.response?.data?.message || err.response?.data?.error
              }`,
              type: "error",
            });
            setLoading(false);
          })
      : setNotify({
          isOpen: true,
          message: "Enter Valid Email!",
          type: "error",
        });
  };
  const updateresume = () => {
    setLoading(true);
    console.log(initialValues);
    UpdateResume(initialValues)
      .then((res) => {
        console.log("update resume", res);

        navigate("/UpdateSuccess");
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `${
            err.response?.data?.message || err.response?.data?.error
          }`,
          type: "error",
        });
        setLoading(false);
      });
  };
  const [age, setAge] = useState("");

  const handleChangeage = (event) => {
    setAge(event.target.value);
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    setinitialValues({
      ...initialValues,
      file: event.target.files[0],
    });
    setFile(event.target.files[0]);
  };
  const handleRemove = () => {
    setFile(null);
    setinitialValues({
      ...initialValues,
      file: null,
    });
  };

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };
   const [value, setValue] = useState();
  useEffect(() => {
    setinitialValues({
      ...initialValues,
      contact: value,
    });
  }, [value]);
  return (
    <Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            verticalAlign: "middle",
            marginTop: "13rem",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box m="20px" height="80vh" overflow="auto" paddingRight="1rem">
          <Box mb="20px" display="flex" justifyContent="space-between">
            <Box>
              <KeyboardBackspaceIcon
                color="#fff"
                fontSize="large"
                onClick={() => navigate("/Developers")}
                className="crsr"
              />
            </Box>
            <Box
              onClick={() =>
                dispatch(
                  FuncDeleteDev({
                    id: initialValues._id,
                    resumeid: initialValues.resumeid,
                  })
                )
              }
            >
              <DeletePopup />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box>
              <Header title="Developers" subtitle="Managing the Developers" />
            </Box>
            <Box>
              <Button
                variant="outlined"
                endIcon={<BorderColorIcon />}
                sx={{
                  color: "#fff !important",
                  borderColor: "#fff",
                  ":hover": {
                    color: `${colors.greenAccent[400]} !important`,
                    borderColor: `${colors.greenAccent[400]}`,
                  },
                }}
                onClick={() => dispatch(Funcauthentication(false))}
              >
                Update&nbsp;Developer
              </Button>
            </Box>
          </Box>

          <form>
            <Header
              title="Personal Information"
              subtitle="Your Name & Contact Information"
            />
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                // onBlur={handleBlur}
                onChange={(e) =>
                  setinitialValues({
                    ...initialValues,
                    fullName: e.target.value,
                  })
                }
                value={initialValues.fullName}
                name="firstName"
                // error={!!touched.firstName && !!errors.firstName}
                // helpertext={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
                disabled={admindata?.developer?.isauthenticated}
              />

              <TextField
                required
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                // onBlur={handleBlur}
                value={initialValues.email}
                onChange={(e) => {
                  setinitialValues({
                    ...initialValues,
                    email: e.target.value,
                  });
                }}
                name="email"
                // error={!!touched.email && !!errors.email}
                // helpertext={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
                disabled={admindata?.developer?.isauthenticated}
              />
              <FormControl
                variant="standard"
                disabled={admindata?.developer?.isauthenticated}
                sx={{ minWidth: 120, gridColumn: "span 2" }}
              >
                {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
                <InputLabel id="demo-simple-select-standard-label">
                  Country
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={initialValues.country}
                  onChange={(e) =>
                    setinitialValues({
                      ...initialValues,
                      country: e.target.value,
                    })
                  }
                  label="Country"
                  // onBlur={handleBlur}
                  // error={!!touched.contact && !!errors.contact}
                  // helpertext={touched.contact && errors.contact}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {countries?.map((country) => (
                    <MenuItem value={country.name}>{country.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box sx={{ gridColumn: "span 2" }}>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={initialValues.contact}
                  onChange={setValue}
                  readOnly={admindata?.developer?.isauthenticated}
                />
              </Box>
              <TextField
                required
                fullWidth
                variant="filled"
                type="number"
                label="Contact Number"
                // onBlur={handleBlur}
                onChange={(e) =>
                  setinitialValues({
                    ...initialValues,
                    contact: e.target.value,
                  })
                }
                value={initialValues.contact}
                name="contact"
                // error={!!touched.contact && !!errors.contact}
                // helpertext={touched.contact && errors.contact}
                sx={{ gridColumn: "span 2" }}
                disabled={admindata?.developer?.isauthenticated}
              />
              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label="Position Applying For"
                // onBlur={handleBlur}
                onChange={(e) =>
                  setinitialValues({
                    ...initialValues,
                    position: e.target.value,
                  })
                }
                value={initialValues.position}
                name="position"
                // error={!!touched.lastName && !!errors.lastName}
                // helpertext={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
                disabled={admindata?.developer?.isauthenticated}
              />
            </Box>
            <Box marginTop="3rem"></Box>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl
                variant="standard"
                disabled={admindata?.developer?.isauthenticated}
                sx={{ minWidth: 120, gridColumn: "span 2" }}
              >
                {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
                <InputLabel id="demo-simple-select-standard-label">
                  How many years of experience do you have?
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={initialValues.experience}
                  onChange={(e) =>
                    setinitialValues({
                      ...initialValues,
                      experience: e.target.value,
                    })
                  }
                  label="Experience"
                  // onBlur={handleBlur}
                  // error={!!touched.contact && !!errors.contact}
                  // helpertext={touched.contact && errors.contact}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
                  <MenuItem value="1+ Year">1+ Year</MenuItem>
                  <MenuItem value="2+ Year">2+ Year</MenuItem>
                  <MenuItem value="3+ Year">3+ Year</MenuItem>
                  <MenuItem value="4+ Year">4+ Year</MenuItem>
                  <MenuItem value="5+ Year">5+ Year</MenuItem>
                  <MenuItem value="6+ Year">6+ Year</MenuItem>
                  <MenuItem value="7+ Year">7+ Year</MenuItem>
                  <MenuItem value="8+ Year">8+ Year</MenuItem>
                  <MenuItem value="9+ Year">9+ Year</MenuItem>
                  <MenuItem value="10+ Year">10+ Year</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                disabled={admindata?.developer?.isauthenticated}
                sx={{ minWidth: 120, gridColumn: "span 2" }}
              >
                {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
                <InputLabel id="demo-simple-select-standard-label">
                  How soon can you join us upon selection?
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={initialValues.joiningtime}
                  onChange={(e) =>
                    setinitialValues({
                      ...initialValues,
                      joiningtime: e.target.value,
                    })
                  }
                  label="Experience"
                  // onBlur={handleBlur}
                  // error={!!touched.contact && !!errors.contact}
                  // helpertext={touched.contact && errors.contact}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1-7 Days">1-7 Days</MenuItem>
                  <MenuItem value="14 Days">14 Days</MenuItem>
                  <MenuItem value="30 Days">30 Days</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
            required
            fullWidth
            variant="filled"
            type="text"
            label="Specialization"
            // onBlur={handleBlur}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                specialization: e.target.value,
              })
            }
            value={initialValues.specialization}
            name="Specialization"
            // error={!!touched.specialization && !!errors.specialization}
            // helpertext={touched.specialization && errors.specialization}
            sx={{ gridColumn: "span 2" }}
          /> */}
            </Box>
            <Box marginTop="3rem"></Box>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl
                variant="standard"
                disabled={admindata?.developer?.isauthenticated}
                sx={{ minWidth: 120, gridColumn: "span 2" }}
              >
                {/* <Header
              title=""
              subtitle="How Many Years Of Experience Do You Have?"
            /> */}
                <InputLabel id="demo-simple-select-standard-label">
                  What is your level of proficiency in English?
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={initialValues.level}
                  onChange={(e) =>
                    setinitialValues({
                      ...initialValues,
                      level: e.target.value,
                    })
                  }
                  label="Age"
                  // onBlur={handleBlur}
                  // error={!!touched.contact && !!errors.contact}
                  // helpertext={touched.contact && errors.contact}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem value="Good">Good</MenuItem>
                  <MenuItem value="Average">Average</MenuItem>
                  <MenuItem value="Poor">Poor</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box marginTop="3rem">
              {" "}
              <Header title="Resume" subtitle="" />
            </Box>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? "span 4" : "span 4" },
              }}
            >
              <Box
                variant="standard"
                sx={{
                  minWidth: 120,
                  gridColumn: "span 2",
                  backgroundColor: "#323848",
                  padding: "1rem",
                  borderRadius: "3px",
                  borderBottom: "1px solid #fff",
                }}
              >
                {initialValues?.file ? (
                  <Box display="flex" gap={1} className="crsr">
                    <span>
                      {initialValues?.file?.name || initialValues?.file}
                    </span>
                    {!admindata?.developer?.isauthenticated && (
                      <CancelIcon onClick={handleRemove} />
                    )}
                  </Box>
                ) : (
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx"
                    onChange={handleFileChange}
                    disabled={admindata?.developer?.isauthenticated}
                  />
                )}
              </Box>
              <Button
                variant="outlined"
                endIcon={<BorderColorIcon />}
                sx={{
                  color: "#fff !important",
                  borderColor: "#fff",
                  ":hover": {
                    color: `${colors.greenAccent[400]} !important`,
                    borderColor: `${colors.greenAccent[400]}`,
                  },
                }}
                onClick={() =>
                  window.open(
                    initialValues?.file?.name || initialValues?.file,
                    "_blank"
                  )
                }
              >
                <span className="crsr">View Resume </span>
              </Button>
              <Box marginTop="3rem">
                {" "}
                <Header title="Skills" subtitle="" />
              </Box>
              <Box
                variant="standard"
                sx={{
                  minWidth: 120,
                  gridColumn: "span 4",
                  backgroundColor: "#323848",
                  padding: "1rem",
                  borderRadius: "3px",
                  borderBottom: "1px solid #fff",
                }}
              >
                <TagsInput skills={initialValues?.tags} />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {loading ? (
                <Box display="flex" justifyContent="center" mt="1rem">
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleFormSubmit()}
                  disabled={
                    initialValues.firstName === "" ||
                    initialValues.firstName === "" ||
                    initialValues.email === "" ||
                    initialValues.contact === "" ||
                    initialValues.qualification === "" ||
                    initialValues.specialization === "" ||
                    initialValues.file === null ||
                    admindata?.developer?.tags?.length < 1 ||
                    admindata?.developer?.isauthenticated
                  }
                >
                  Update Profile
                </Button>
              )}
            </Box>
          </form>
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export default DeveloperDetails;
