import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import bgimage from "../../assets/otp.png";
import {
  Funcauthentication,
  FuncotpToken,
} from "../../reduxtoolkit/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../actions/alert";
import { SendOtp, verifyOtp } from "../../api/authsection";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function VerifyOtp() {
  const { email } = useParams();
  const dispatch = useDispatch();
  const admindata = useSelector((state) => state);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(Funcauthentication(false));
    console.log(sessionStorage.getItem("otptoken"));
  }, []);
  const [isLoading, setisLoading] = useState(false);
  const [otp, setotp] = useState("");
  const handleSubmit = () => {
    setisLoading(true);
    otp === "" ? (
      <>
        {setNotify({
          isOpen: true,
          message: `Enter OTP To Verify! `,
          type: "error",
        })}
        {setisLoading(false)}
      </>
    ) : (
      varifyOtp()
    );
  };
  const varifyOtp = async () => {
    await verifyOtp(otp, admindata?.loginadmin?.otpToken?.otpToken)
      .then((res) => {
        console.log(res);
        dispatch(FuncotpToken(res));
        setisLoading(false);
        setNotify({
          isOpen: true,
          message: `OTP Verified Successfully!`,
          type: "success",
        });
        setTimeout(() => {
          navigate(`/ResetPassword/${email}`);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message || err.response.data.error} `,
          type: "error",
        });
        setisLoading(false);
      });
  };
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [counter, setcounter] = useState(59);
  const [issending, setissending] = useState(false);
  const getOtp = async () => {
    setissending(true);
    await SendOtp(email)
      .then((res) => {
        console.log(res);
        setisLoading(false);
        sessionStorage.setItem("otptoken", res?.otpToken);
        dispatch(FuncotpToken(res));
        setNotify({
          isOpen: true,
          message: `OTP sent to given email!`,
          type: "success",
        });
        setissending(false);

        setcounter(59);
      })
      .catch((err) => {
        console.log(err);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message} `,
          type: "error",
        });
        setissending(false);
      });
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setcounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);
  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgimage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          alignItems="center"
        >
          <Box
            sx={{
              my: 18,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Enter&nbsp;OTP
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                type="number"
                fullWidth
                id="email"
                label="OTP"
                name="email"
                onChange={(e) => setotp(e.target.value)}
                autoComplete="email"
                autoFocus
              />
              <FormControl
                sx={{ mt: "1rem", width: "100%" }}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  className="poshide"
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  className="poshide"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {isLoading ? (
                <Box display="flex" justifyContent="center" mb="1rem">
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <>
                  <Button
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: -10,
                      mb: 2,
                      backgroundColor: `${colors.greenAccent[700]}`,
                    }}
                  >
                    Verify
                  </Button>

                  <Box
                    display="flex"
                    justifyContent="end"
                    mt="-1.5rem"
                    mb="1rem"
                  >
                    <p className=" flex justify-content-end mt-2 mr-2">
                      Didn’t Receive OTP?{" "}
                      <span className="resand ms-2 ">
                        {" "}
                        {counter === 0 ? (
                          <p
                            className="for__link text-green-600 crsr"
                            onClick={() => getOtp()}
                          >
                            &nbsp;{issending ? "Sending otp..." : "Resend Otp"}
                          </p>
                        ) : (
                          <div style={{ color: "#fff" }}>
                            &nbsp;&nbsp;00:{counter}
                          </div>
                        )}
                      </span>
                    </p>
                  </Box>
                </>
              )}

              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Link href="#" variant="body2" color="#fff">
                    <span onClick={() => navigate("/")}>
                      Remember&nbsp;password?&nbsp;-&nbsp;Login
                    </span>
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />;
    </Box>
  );
}
