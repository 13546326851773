import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
// import {
//   Funcauthentication,
//   FuncisAuthorized,
// } from "../../../reduxtoolkit/loginSlice";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Notification from "../../actions/alert";
import { DeleteSkill } from "../../api/skills";
import { Funciscallskill } from "../../reduxtoolkit/loginSlice";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DeletePopup() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isLoading, setisLoading] = useState(false);
  const handleDelete = () => {
    setisLoading(true);
    DeleteSkill(adminData?.loginadmin?.skillid)
      .then((res) => {
        setisLoading(false);
        handleClose();
        setNotify({
          isOpen: true,
          message: "Skill Deleted!",
          type: "success",
        });
        dispatch(Funciscallskill(true));
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message || err.response.data.error} `,
          type: "error",
        });
      });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        {/* <span className="text-white">Logout</span> */}
        <DeleteForeverIcon className="delicon" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Delete Skill?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are You Sure You want to Delete this Skill?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} className="text-green-500">
            <span className="text-green-500">Cancel</span>
          </Button>
          <Button onClick={handleDelete} className="text-green-500">
            {isLoading ? (
              <span className="text-green-500">Deleting...</span>
            ) : (
              <span className="text-green-500">Delete</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
