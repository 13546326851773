import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "loginadmin",
  initialState: {
    isauthenticated: false,
    token: "",
    otpToken: "",
    isAuthorized: false,
    skillid: "",
    iscallskill : false
  },
  reducers: {
    Funciscallskill: (state, action) => {
      state.iscallskill = action.payload;
      // console.log(action.payload);
    },
    Funcskillid: (state, action) => {
      state.skillid = action.payload;
      // console.log(action.payload);
    },
    Funcauthentication: (state, action) => {
      state.isauthenticated = action.payload;
      // console.log(action.payload);
    },
    FuncToken: (state, action) => {
      state.token = action.payload;
      // console.log(action.payload);
    },
    FuncotpToken: (state, action) => {
      state.otpToken = action.payload;
      // console.log(action.payload);
    },
    FuncisAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
      // console.log(action.payload);
    },
  },
});
export const { Funcauthentication, FuncToken, FuncotpToken, FuncisAuthorized, Funcskillid, Funciscallskill } =
  loginSlice.actions;

export default loginSlice.reducer;
