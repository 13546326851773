import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FuncTags } from "../../../reduxtoolkit/developerSlice";
import Notification from "../../../actions/alert";
import { Box, Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./index.css";
import { getAllDeveloperskills } from "../../../api/developer";

function TagsInput({ skills }) {
  const isNonMobile = useMediaQuery("(min-width:800px)");

  const admindata = useSelector((state) => state);
  const [initialValues, setinitialValues] = useState({
    value: "select",
    experience: "select",
  });
  const dispatch = useDispatch();
  const [tags, setTags] = useState(skills || []);
  const [searchdata, setsearchdata] = useState("");
  const [allskills, setallskills] = useState([]);
  const getskills = () => {
    getAllDeveloperskills()
      .then((res) => {
        setallskills(res?.data?.skills);
        // console.log(res);
      })
      .catch((err) => {
        setallskills([]);
        console.log(err);
      });
  };
  useEffect(() => {
    getskills();
    // setTags(skill || ["Github"]);
  }, []);

  console.log(tags);
  const handleKeyDown = (e) => {
    setsearchdata(e.target.value);
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    dispatch(FuncTags([...tags, value]));
    e.target.value = "";
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const setdata = () => {
    console.log(tags.filter((item) => item.value === initialValues?.value));
    if (tags.find((item) => item.value === initialValues?.value)) {
      setNotify({
        isOpen: true,
        message: `The element ${initialValues?.value} already exists in the list`,
        type: "error",
      });
    } else {
      setTags([
        ...tags,
        {
          value: initialValues?.value,
          experience: initialValues?.experience,
        },
      ]);
      clearlist();
    }
  };
  useEffect(() => {
    dispatch(FuncTags([...tags]));
  }, [tags]);
  const removeTag = (name) => {
    console.log("removeTag", name);
    setTags(tags?.filter((i) => i?.value !== name));
  };
  const clearlist = () => {
    setinitialValues({
      value: "",
      experience: "",
    });
  };
  return (
    <div>
      <div className="tags-input-container mb-5">
        {tags.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text">
              {tag?.value}&nbsp;({tag?.experience})
            </span>
            <span className="close" onClick={() => removeTag(tag?.value)}>
              &times;
            </span>
          </div>
        ))}
        {/* <input
          // onKeyDown={handleKeyDown}
          type="text"
          className="tags-input"
          placeholder="Select skill and experience from below"
          value={searchdata}
          onChange={(e) => setsearchdata(e.target.value)}
          readOnly={admindata?.developer?.isauthenticated}
        /> */}
      </div>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <FormControl
          variant="standard"
          sx={{ minWidth: 120, gridColumn: "span 2" }}
          disabled={admindata?.developer?.isauthenticated}
        >
          {/* <Header
              title=""  
              subtitle="What is your level of proficiency in English"
            /> */}
          <small id="demo-simple-select-standard-label">Select Skill </small>
          <Select
            variant="filled"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={initialValues.value}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                value: e.target.value,
              })
            }
            label="Skill"
            // onBlur={handleBlur}
            // error={!!touched.contact && !!errors.contact}
            // helpertext={touched.contact && errors.contact}
          >
            <MenuItem value="select">
              <em>Select</em>
            </MenuItem>
            {allskills?.map((skill) =>
              skill?.skill?.map((data) => (
                <MenuItem value={data}>{data}</MenuItem>
              ))
            )}
            {/* <MenuItem value="Less than 1 year">Less than 1 year</MenuItem> */}
          </Select>
        </FormControl>

        <FormControl
          variant="standard"
          disabled={admindata?.developer?.isauthenticated}
          sx={{ minWidth: 120, gridColumn: "span 2" }}
        >
          {/* <Header
              title=""
              subtitle="How Many Years Of Experience Do You Have?"
            /> */}
          <small
            // id="demo-simple-select-standard-label "
            className="text-[#fff]"
          >
            Experience{" "}
          </small>
          <Select
            variant="filled"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={initialValues.experience}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                experience: e.target.value,
              })
            }
            label="Experience"
            // onBlur={handleBlur}
            // error={!!touched.contact && !!errors.contact}
            // helpertext={touched.contact && errors.contact}
          >
            <MenuItem value="select">
              <em>Select</em>
            </MenuItem>

            <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
            <MenuItem value="1+ Year">1+ Year</MenuItem>
            <MenuItem value="2+ Year">2+ Year</MenuItem>
            <MenuItem value="3+ Year">3+ Year</MenuItem>
            <MenuItem value="4+ Year">4+ Year</MenuItem>
            <MenuItem value="5+ Year">5+ Year</MenuItem>
            <MenuItem value="6+ Year">6+ Year</MenuItem>
            <MenuItem value="7+ Year">7+ Year</MenuItem>
            <MenuItem value="8+ Year">8+ Year</MenuItem>
            <MenuItem value="9+ Year">9+ Year</MenuItem>
            <MenuItem value="10+ Year">10+ Year</MenuItem>
          </Select>
        </FormControl>
        {/* <TextField
            required
            fullWidth
            variant="filled"
            type="text"
            label="Specialization"
            // onBlur={handleBlur}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                specialization: e.target.value,
              })
            }
            value={initialValues.specialization}
            name="Specialization"
            // error={!!touched.specialization && !!errors.specialization}
            // helpertext={touched.specialization && errors.specialization}
            sx={{ gridColumn: "span 2" }}
          /> */}
        <Button
          sx={{ minWidth: 100, gridColumn: "span 1" }}
          color="secondary"
          variant="contained"
          className="px-5"
          onClick={() => setdata()}
          disabled={
            initialValues.experience === "select" ||
            initialValues.value === "select"
          }
        >
          Add&nbsp;Skill
        </Button>
      </Box>
      {/* <div className="bg-bgtags px-5 py-5 overflow-auto h-[20rem] block w-[50%]">
        {skills
          .filter((data) => {
            if (searchdata === "") {
              return skills;
            } else {
              return data.includes(searchdata);
            }
          })
          .map((data) => (
            <h5
              onClick={() => setdata(data)}
              className="cursor-pointer mb-2 text-[18px] hover:bg-colorgreen rounded-full hover:py-1 hover:px-2   hover:w-[10rem]"
            >
              {data}
            </h5>
          ))}
      </div> */}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default TagsInput;
