import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import { AddSkill } from "../../api/skills";
import Notification from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { Funciscallskill } from "../../reduxtoolkit/loginSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { getAllDeveloperskills } from "../../api/developer";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {
  Funccallfilter,
  Funcfilterdata,
} from "../../reduxtoolkit/developerSlice";

export default function FilterPopup() {
  const admindata = useSelector((state) => state);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [allskills, setallskills] = useState([]);
  const getskills = () => {
    getAllDeveloperskills()
      .then((res) => {
        setallskills(res?.data?.skills);
        console.log(res);
      })
      .catch((err) => {
        setallskills([]);
        console.log(err);
      });
  };
  useEffect(() => {
    getskills();
    // setTags(skill || ["Github"]);
  }, []);
  const theme = useTheme();
  const adminData = useSelector((state) => state);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const colors = tokens(theme.palette.mode);
  const [skill, setskill] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [initialValues, setinitialValues] = useState({
    skill: "",
    experience: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  console.log(initialValues);
  const handleAddSkill = () => {
    //   setisLoading(true);
    dispatch(Funcfilterdata(initialValues));

    handleClose();
    dispatch(Funccallfilter(true));
    // AddSkill(skill)
    //   .then((res) => {
    //     setisLoading(false);
    //     handleClose();
    //     setNotify({
    //       isOpen: true,
    //       message: "Skill Added Successfully!",
    //       type: "success",
    //     });
    //     dispatch(Funciscallskill(true));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setisLoading(false);
    //     setNotify({
    //       isOpen: true,
    //       message: `${err.response.data.message || err.response.data.error} `,
    //       type: "error",
    //     });
    //   });
  };

  return (
    <div>
      {/* <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ color: "#fff" }}
      > */}
      {/* <Button
        variant="outlined"
        endIcon={<AddIcon />}
        sx={{
          color: "#fff !important",
          borderColor: "#fff",
          ":hover": {
            color: `${colors.greenAccent[400]} !important`,
            borderColor: `${colors.greenAccent[400]}`,
          },
        }}
        onClick={handleClickOpen}
      >
        Add&nbsp;Skill
      </Button> */}
      <Button onClick={handleClickOpen}>
        {/* <span className="text-white">Logout</span> */}
        <FilterAltIcon className="delicon" />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Filter&nbsp;Developers</DialogTitle>
        <DialogContent>
          <Box display="flex" gap={10} justifyContent="between">
            <FormControl
              variant="standard"
              sx={{ minWidth: 200, gridColumn: "span 3" }}
              // disabled={admindata?.developer?.isauthenticated}
            >
              {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
              <InputLabel id="demo-simple-select-standard-label">
                Select Skill{" "}
              </InputLabel>
              <Select
                variant="filled"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={initialValues.skill}
                onChange={(e) =>
                  setinitialValues({
                    ...initialValues,
                    skill: e.target.value,
                  })
                }
                label="Skill"
                // onBlur={handleBlur}
                // error={!!touched.contact && !!errors.contact}
                // helpertext={touched.contact && errors.contact}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {allskills?.map((skill) =>
                  skill?.skill?.map((data) => (
                    <MenuItem value={data}>{data}</MenuItem>
                  ))
                )}
                {/* <MenuItem value="Less than 1 year">Less than 1 year</MenuItem> */}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              // disabled={admindata?.developer?.isauthenticated}
              sx={{ minWidth: 200, gridColumn: "span 3" }}
            >
              {/* <Header
              title=""
              subtitle="How Many Years Of Experience Do You Have?"
            /> */}
              <InputLabel id="demo-simple-select-standard-label">
                Experience{" "}
              </InputLabel>
              <Select
                variant="filled"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={initialValues.experience}
                onChange={(e) =>
                  setinitialValues({
                    ...initialValues,
                    experience: e.target.value,
                  })
                }
                label="Experience"
                // onBlur={handleBlur}
                // error={!!touched.contact && !!errors.contact}
                // helpertext={touched.contact && errors.contact}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
                <MenuItem value="1+ Year">1+ Year</MenuItem>
                <MenuItem value="2+ Year">2+ Year</MenuItem>
                <MenuItem value="3+ Year">3+ Year</MenuItem>
                <MenuItem value="4+ Year">4+ Year</MenuItem>
                <MenuItem value="5+ Year">5+ Year</MenuItem>
                <MenuItem value="6+ Year">6+ Year</MenuItem>
                <MenuItem value="7+ Year">7+ Year</MenuItem>
                <MenuItem value="8+ Year">8+ Year</MenuItem>
                <MenuItem value="9+ Year">9+ Year</MenuItem>
                <MenuItem value="10+ Year">10+ Year</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {" "}
            <span className="text-green-500">Cancel</span>
          </Button>
          <Button onClick={() => handleAddSkill()}>
            {" "}
            {isLoading ? (
              <span className="text-green-500">Filtering...</span>
            ) : (
              <span className="text-green-500">Filter</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
