import React from "react";
import Lottie from "react-lottie";
import notfound from "../assets/notfound.json";
import { Box, Typography, useTheme } from "@mui/material";

function NotFoundLotie() {
  const notfoundOptions = {
    loop: true,
    autoplay: true,
    animationData: notfound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="mt-[3rem]">

      <Lottie
        options={notfoundOptions}
        width={250}
        height={250}
        //   backgroundColor={"grey"}
      />
    </div>
  );
}

export default NotFoundLotie;
