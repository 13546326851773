import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {
  return (
    <div>
      <CircularProgress
        sx={{
          color: "#3DA58A",
        }}
      />
    </div>
  );
};

export default Loader;
