import axios from "axios";
console.log(sessionStorage.getItem("token"));
export const getAllSkills = () =>
  axios.get("/user/skill/", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });

  export const AddSkill = async (data) => {
  console.log(data);
   const params = new URLSearchParams();

   params.append("skill[]", data);

  return await axios.post("/user/skill", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};

  export const DeleteSkill = async (id) => {
  return await axios.delete(`/user/skill/${id}`, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
