import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockdata";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Funcauthentication } from "../../reduxtoolkit/loginSlice";
import { getAllDevelopers } from "../../api/developer";
import CircularProgress from "@mui/material/CircularProgress";
import NotFoundLotie from "../../helpers/notfoundloties";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getAllClients, updateStatusclient } from "../../api/clients";
import NotePopupClient from "./popup";
import { FuncCallapiDev } from "../../reduxtoolkit/clientSlice";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Loader from "../../helpers/loader";
import Notification from "../../actions/alert";

const AllClients = () => {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state);
  console.log(clientData);
  const theme = useTheme();
  const [developerid, setdeveloperid] = useState("");
  const [statusloader, setstatusloader] = useState(false);
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [AllDevelopersarr, setAllDevelopersarr] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getDevelopers = async () => {
    setisLoading(true);
    await getAllClients()
      .then((res) => {
        console.log(res);
        setAllDevelopersarr(res.data?.data?.docs?.applicants?.docs);
        setisLoading(false);
        dispatch(FuncCallapiDev(false));
      })
      .catch((err) => {
        console.log(err);
        setAllDevelopersarr([]);
        setisLoading(false);
      });
  };

  const updateStatus = async (value, id) => {
    setstatusloader(true);
    await updateStatusclient(value, id)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: "Status Updated Successfully!",
          type: "success",
        });
        setstatusloader(false);
        getDevelopers();
        console.log(res);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `${
            err.response?.data?.message || err.response?.data?.error
          }`,
          type: "error",
        });
        setstatusloader(false);

        console.log(err);
      });
  };

  const getFullName = (params) => {
    return `${params.row.firstname || ""} ${params.row.lastname || ""}`;
  };
  const getEducations = (params) => {
    return `${params.row.qualification || ""} ${
      params.row.specialization || ""
    }`;
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",

      flex: 1,
      headerAlign: "left",
      align: "left",
      //   cellClassName: "name-column--cell",
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Lead_Type",
      headerName: "Lead_Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { status, _id } }) => {
        return (
          <Box
            // backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            className="crsr"
            // onClick={() => window.open(resume, "_blank")}
          >
            {developerid === _id && statusloader ? (
              <Loader />
            ) : (
              <FormControl fullWidth>
                {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Status
              </InputLabel> */}
                <NativeSelect
                  sx={{
                    color:"green" ,
                    // color: status === "verified" ? "green" : "red",
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setdeveloperid(_id);
                    updateStatus(e.target.value, _id);
                  }}
                  defaultValue={status}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value="Lead-in">Lead-in</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Requirements">Requirements&nbsp;Defined</option>
                   <option value="Interview">Interview&nbsp;scheduled</option>
                   <option value="Interviewed">Interviewed</option>
                  <option value="Won">Won</option>
                  <option value="Lost">Lost</option>
                </NativeSelect>
              </FormControl>
            )}
          </Box>
        );
      },
    },
    {
      field: "note",
      headerName: "Note",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { note, _id } }) => {
        return (
          <Box borderRadius="4px" className="crsr">
            {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}
            <NotePopupClient note={note ? note : ""} id={_id} />
          </Box>
        );
      },
    },
    {
      field: "",
      headerName: "Action",
      headerAlign: "let",
      align: "left",
      flex: 1,
      renderCell: ({ row: { _id } }) => {
        return (
          <Box
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="left"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            className="crsr"
            onClick={() => navigate(`/ClientDetail/${_id}`)}
          >
            {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              View&nbsp;Details
            </Typography>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    console.log(clientData?.client?.callapi);
    dispatch(Funcauthentication(true));
    getDevelopers();
  }, [clientData?.client?.callapi]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Header title="Clients" subtitle="Managing the Clients" />
        </Box>
        <Box>
          <Button
            variant="outlined"
            endIcon={<AddIcon />}
            sx={{
              color: "#fff !important",
              borderColor: "#fff",
              ":hover": {
                color: `${colors.greenAccent[400]} !important`,
                borderColor: `${colors.greenAccent[400]}`,
              },
            }}
            onClick={() => navigate("/selectSkills")}
          >
            Add&nbsp;Client
          </Button>
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {
          //
          isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ verticalAlign: "middle" }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : AllDevelopersarr?.length > 0 ? (
            <DataGrid
              className="crsr"
              //   checkboxSelection
              loading={isLoading}
              rows={AllDevelopersarr && AllDevelopersarr}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row._id}
              // paginationMode="server"
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ verticalAlign: "middle" }}
            >
              <Box>
                <Typography
                  variant="h2"
                  className="flex justify-center mb-[4rem]"
                >
                  No Client Found!{" "}
                </Typography>
                {NotFoundLotie()}{" "}
              </Box>
            </Box>
          )
        }
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default AllClients;
