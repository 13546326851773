import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { tokens } from "../../theme";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import bgimage from "../../assets/loginbg.jpg";
import { Funcauthentication } from "../../reduxtoolkit/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changepassapi, ResetPassword } from "../../api/authsection";
import Notification from "../../actions/alert";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Resetpassword() {
  const admindata = useSelector((state) => state);

  const { email } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Funcauthentication(false));
  }, []);
  const theme = useTheme();
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const colors = tokens(theme.palette.mode);
  const [isLoading, setisLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordconfrm, setShowPasswordconfrm] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordconfrm = () =>
    setShowPasswordconfrm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [error, setError] = useState({
    length: false,
    small: false,
    capital: false,
    number: false,
    special: false,
  });
  const HandleChangePassword = (confirmpassword) => {
    const hasMinLength = confirmpassword?.length >= 8;
    const hasLowerCase = /[a-z]/.test(confirmpassword);
    const hasUpperCase = /[A-Z]/.test(confirmpassword);
    const hasNumber = /\d/.test(confirmpassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(confirmpassword);

    setError({
      length: hasMinLength ? true : false,
      small: hasLowerCase ? true : false,
      capital: hasUpperCase ? true : false,
      number: hasNumber ? true : false,
      special: hasSpecialChar ? true : false,
    });
    setpassword(confirmpassword);
  };
  const [ismatched, setismatched] = useState(true);
  const handleconfrimpass = (e) => {
    password !== e ? (
      setismatched(false)
    ) : (
      <>
        {setismatched(true)}
        {setconfirmpassword(e)}
      </>
    );
  };
  useEffect(() => {
    confirmpassword !== "" &&
      password !== "" &&
      handleconfrimpass(confirmpassword);
  }, [password]);

  const HandleResetPassword = async () => {
    setisLoading(true);
    await changepassapi(
      email,
      confirmpassword,
      admindata?.loginadmin?.otpToken?.token
    )
      .then((res) => {
        console.log(res);

        setisLoading(false);

        setNotify({
          isOpen: true,
          message: `Password Reset Succesfully! `,
          type: "success",
        });
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((error) => {
        setisLoading(false);

        setNotify({
          isOpen: true,
          message: `${
            error.response.data.message || error.response.data.error
          }`,
          type: "error",
        });
        console.log(error);
      });
  };
  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgimage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          alignItems="center"
        >
          <Box
            sx={{
              my: 18,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password{" "}
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <FormControl
                sx={{ mt: "1rem", width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => HandleChangePassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControl
                sx={{ mt: "1rem", width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm&nbsp;Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password2"
                  type={showPasswordconfrm ? "text" : "password"}
                  onChange={(e) => handleconfrimpass(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordconfrm}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordconfrm ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                <small className="text-red-500 mt-2">
                  {ismatched ? "" : "Password Did'nt Matched!"}
                </small>
              </FormControl>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              /> */}
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              {isLoading ? (
                <Box display="flex" justifyContent="center" mb="1rem">
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: `${colors.greenAccent[700]}`,
                  }}
                  disabled={
                    error.length &&
                    error.small &&
                    error.capital &&
                    error.number &&
                    error.special &&
                    ismatched
                      ? false
                      : true
                  }
                  onClick={() => HandleResetPassword()}
                >
                  Reset Password{" "}
                </Button>
              )}
              <Grid container>
                <Grid item xs>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="left"
                    justifyItems="left"
                    gap={1}
                  >
                    {!error?.length && (
                      <small className="text-red-500">
                        Password must have minimum 8 characters
                      </small>
                    )}
                    {!error?.small && (
                      <small className="text-red-500">
                        Password must have minimum 1 small letter
                      </small>
                    )}
                    {!error?.capital && (
                      <small className="text-red-500">
                        Password must have 1 capital letter
                      </small>
                    )}
                    {!error?.number && (
                      <small className="text-red-500">
                        Password must have 1 number
                      </small>
                    )}
                    {!error?.special && (
                      <small className="text-red-500">
                        Password must have 1 special character
                      </small>
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" color="#fff">
                    Remember password?&nbsp; Login
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
            <div></div>
          </Box>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />;
    </Box>
  );
}
