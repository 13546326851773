import axios from "axios";

export const LoginApi = (data) => axios.post("/user/login", data);
export const SendOtp = async (email) => {
  console.log(email);
  const { data } = await axios.post("/user/send-otp", { email: email });
  console.log(data);
  return data;
};
export const verifyOtp = async (otp, token) => {
  console.log(otp, token);
  const { data } = await axios.post("/user/verify-otp", {
    otp: otp,
    token: token,
  });
  console.log(data);
  return data;
};
export const changepassapi = async (email, password, token) => {
    console.log(email, password, token);
     const params = new URLSearchParams();

     params.append("token", token);
     params.append("email", email);
     params.append("password", password);
  const { data } = await axios.post("/user/reset-password", params);
  console.log(data);
  return data;
};
