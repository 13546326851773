import axios from "axios";
console.log(sessionStorage.getItem("token"));
export const getAllClients = () =>
  axios.get("/user/view_hiring_applicants", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
export const getAllClientsdetails = (id) =>
  axios.get(`/user/view_hiring_applicants/${id}`, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
export const updateNote = async (data, id) => {
  console.log(data);
  console.log(id);
  const params = new URLSearchParams();

  params.append("id", id);
  params.append("note", data);

  return await axios.patch("/user/Update_applicant", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
export const updateStatusclient = async (data, id) => {
  console.log(data);
  console.log(id);
  const params = new URLSearchParams();

  params.append("id", id);
  params.append("status", data);

  return await axios.patch("/user/Update_applicant", params, {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};