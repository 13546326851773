import React, { useState } from "react";
import Topbar from "./screens/global/topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Sidebar from "./screens/global/sidebar";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import AllDevelopers from "./screens/Developers";
import AddNewDeveloper from "./screens/Developers/AddDeveloper";
import DeveloperDetails from "./screens/Developers/DeveloperDetails";
import LoginScreen from "./screens/Authentications/Login";
import OtpEmail from "./screens/Authentications/email";
import VerifyOtp from "./screens/Authentications/otp";
import Resetpassword from "./screens/Authentications/resetpassword";
import { useSelector } from "react-redux";
import SelectSkills from "./screens/clients/selectskills";
import HireDeveloper from "./screens/clients/HireDeveloper";
import SuccessPage from "./screens/Developers/success";
import SuccessRequest from "./screens/clients/successrequest";
import AllClients from "./screens/clients/viewclients";
import ClientsDetails from "./screens/clients/clientDetails";
import PrivateRoute from "./components/privateRoutes/index.js";
import PublicRoute from "./components/publicRoutes/index.js";
import SuccessPageUpdate from "./screens/Developers/successupdate";
import AllSKills from "./screens/Skills";
import CreateDeveloperProfile from "./screens/Developers/AddDeveloper/newdeveloper";

const App = () => {
  const admindata = useSelector((state) => state);
  console.log(admindata);
  const [theme, ColorMode] = useMode();
  const [iscondition, setiscondition] = useState("login");

  return (
    <ColorModeContext.Provider value={ColorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {admindata?.loginadmin?.isauthenticated && <Sidebar />}
          <main
            className={
              admindata?.loginadmin?.isauthenticated
                ? "content"
                : " contentauth"
            }
          >
            {admindata?.loginadmin?.isauthenticated && <Topbar />}
            <Routes>
              <Route
                path="/"
                element={
                  <PublicRoute>
                    {" "}
                    <LoginScreen />
                  </PublicRoute>
                }
              />
              <Route
                path="/Dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/Developers"
                element={
                  <PrivateRoute>
                    <AllDevelopers />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/SignUp"
                element={<CreateDeveloperProfile />}
              />
              <Route path="/AddNewDeveloper" element={<AddNewDeveloper />} />
              <Route path="/SelectSkills" element={<SelectSkills />} />
              <Route path="/Success" element={<SuccessPage />} />
              <Route path="/SuccessRequest" element={<SuccessRequest />} />
              <Route path="/UpdateSuccess" element={<SuccessPageUpdate />} />
              <Route
                path="/DeveloperDetails/:id"
                element={
                  <PrivateRoute>
                    <DeveloperDetails />
                  </PrivateRoute>
                }
              />
              <Route path="/HireDeveloper" element={<HireDeveloper />} />
              <Route
                path="/VerifyEmail"
                element={
                  <PublicRoute>
                    {" "}
                    <OtpEmail />{" "}
                  </PublicRoute>
                }
              />
              <Route
                path="/VerifyOtp/:email"
                element={
                  <PublicRoute>
                    {" "}
                    <VerifyOtp />{" "}
                  </PublicRoute>
                }
              />
              <Route
                path="/Resetpassword/:email"
                element={
                  <PublicRoute>
                    {" "}
                    <Resetpassword />{" "}
                  </PublicRoute>
                }
              />
              <Route
                path="/Clients"
                element={
                  <PrivateRoute>
                    <AllClients />
                  </PrivateRoute>
                }
              />
              <Route
                path="/Skills"
                element={
                  <PrivateRoute>
                    <AllSKills />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ClientDetail/:id"
                element={
                  <PrivateRoute>
                    <ClientsDetails />
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
