import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import CancelIcon from "@mui/icons-material/Cancel";
import TagsInput from "./tags";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { AddDeveloper } from "../../../api/developer";
import validator from "validator";
import Notification from "../../../actions/alert";
import { useNavigate } from "react-router-dom";
import { Funcauthentication } from "../../../reduxtoolkit/developerSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CountrySelect from "react-bootstrap-country-select";
import { countries } from "../../../helpers/countries";

const AddNewDeveloper = () => {
  const [selectedCountry, setselectedCountry] = useState(null);

  // console.log(selectedCountry);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admindata = useSelector((state) => state);
  // console.log("AddNewDeveloper", admindata);
  const isNonMobile = useMediaQuery("(min-width:800px)");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    dispatch(Funcauthentication(false));
  }, []);

  const [initialValues, setinitialValues] = useState({
    fullName: "",
    position: "",
    country: "",
    email: "",
    contact: "",
    qualification: "",
    specialization: "",
    file: null,
    experience: "",
    level: "",
    joiningtime: "",
  });

  const handleFormSubmit = () => {
    // e.preventDefault();
    setLoading(true);
    // console.log(initialValues);
    validator.isEmail(initialValues?.email) ? (
      AddDeveloper(initialValues, admindata?.developer?.tags)
        .then((res) => {
          // console.log(res);

          navigate("/Success");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setNotify({
            isOpen: true,
            message: `${
              err.response?.data?.message || err.response?.data?.error
            }`,
            type: "error",
          });
          setLoading(false);
        })
    ) : (
      <>
        {" "}
        {setNotify({
          isOpen: true,
          message: "Enter Valid Email!",
          type: "error",
        })}
        {setLoading(false)}
      </>
    );
  };
  const [age, setAge] = useState("");

  const handleChangeage = (event) => {
    setAge(event.target.value);
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    // console.log(event.target.files[0]);
    setFile(event.target.files[0]);
    setinitialValues({
      ...initialValues,
      file: event.target.files[0],
    });
  };
  const handleRemove = () => {
    setinitialValues({
      ...initialValues,
      file: null,
    });
    setFile(null);
  };

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  // useEffect(() => {
  //   dispatch(Funcauthentication(false));

  const [value, setValue] = useState();
  useEffect(() => {
    setinitialValues({
      ...initialValues,
      contact: value,
    });
  }, [value]);

  // const [value, setValue] = React.useState("fr");
  // console.log(value);

  return (
    <Box
      m="20px"
      height={admindata?.loginadmin?.isauthenticated ? "80vh" : "90vh"}
      overflow="auto"
      paddingRight="1rem"
    >
      <Box>
        {admindata?.loginadmin?.isauthenticated && (
          <KeyboardBackspaceIcon
            color="#fff"
            fontSize="large"
            onClick={() => navigate("/Developers")}
            className="crsr"
          />
        )}
      </Box>
      <Header
        title={
          admindata?.loginadmin?.isauthenticated
            ? "Add Developer"
            : "Create Developer Profile"
        }
        subtitle=""
      />

      <form>
        <Header
          title="Personal Information"
          subtitle="Your Name & Contact Information"
        />
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <TextField
            required
            fullWidth
            variant="filled"
            type="text"
            label="Full Name"
            // onBlur={handleBlur}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                fullName: e.target.value,
              })
            }
            value={initialValues.fullName}
            name="firstName"
            // error={!!touched.firstName && !!errors.firstName}
            // helpertext={touched.firstName && errors.firstName}
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            required
            fullWidth
            variant="filled"
            type="email"
            label="Email"
            // onBlur={handleBlur}
            onChange={(e) => {
              setinitialValues({
                ...initialValues,
                email: e.target.value,
              });
            }}
            name="email"
            // error={!!touched.email && !!errors.email}
            // helpertext={touched.email && errors.email}
            sx={{ gridColumn: "span 2" }}
          />
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, gridColumn: "span 2" }}
          >
            {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
            <InputLabel id="demo-simple-select-standard-label">
              Country
            </InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={initialValues.country}
              onChange={(e) =>
                setinitialValues({
                  ...initialValues,
                  country: e.target.value,
                })
              }
              label="Country"
              // onBlur={handleBlur}
              // error={!!touched.contact && !!errors.contact}
              // helpertext={touched.contact && errors.contact}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {countries?.map((country) => (
                <MenuItem value={country.name}>{country.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ gridColumn: "span 2" }}>
            <PhoneInput
              placeholder="Enter phone number"
              value={value}
              onChange={setValue}
            />
          </Box>
          {/* <TextField
            required
            fullWidth
            variant="filled"
            type="number"
            label="Contact Number"
            // onBlur={handleBlur}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                contact: e.target.value,
              })
            }
            value={initialValues.contact}
            name="contact"
            // error={!!touched.contact && !!errors.contact}
            // helpertext={touched.contact && errors.contact}
            sx={{ gridColumn: "span 2" }}
          /> */}
          <TextField
            required
            fullWidth
            variant="filled"
            type="text"
            label="Position Applying For"
            // onBlur={handleBlur}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                position: e.target.value,
              })
            }
            value={initialValues.position}
            name="position"
            // error={!!touched.lastName && !!errors.lastName}
            // helpertext={touched.lastName && errors.lastName}
            sx={{ gridColumn: "span 2" }}
          />
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, gridColumn: "span 2" }}
          >
            {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
            <InputLabel id="demo-simple-select-standard-label">
              How many years of experience do you have?
            </InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={initialValues.experience}
              onChange={(e) =>
                setinitialValues({
                  ...initialValues,
                  experience: e.target.value,
                })
              }
              label="Experience"
              // onBlur={handleBlur}
              // error={!!touched.contact && !!errors.contact}
              // helpertext={touched.contact && errors.contact}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
              <MenuItem value="1+ Year">1+ Year</MenuItem>
              <MenuItem value="2+ Year">2+ Year</MenuItem>
              <MenuItem value="3+ Year">3+ Year</MenuItem>
              <MenuItem value="4+ Year">4+ Year</MenuItem>
              <MenuItem value="5+ Year">5+ Year</MenuItem>
              <MenuItem value="6+ Year">6+ Year</MenuItem>
              <MenuItem value="7+ Year">7+ Year</MenuItem>
              <MenuItem value="8+ Year">8+ Year</MenuItem>
              <MenuItem value="9+ Year">9+ Year</MenuItem>
              <MenuItem value="10+ Year">10+ Year</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box marginTop="2rem"></Box>

        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, gridColumn: "span 2" }}
          >
            {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
            <InputLabel id="demo-simple-select-standard-label">
              How soon can you join us upon selection?
            </InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={initialValues.joiningtime}
              onChange={(e) =>
                setinitialValues({
                  ...initialValues,
                  joiningtime: e.target.value,
                })
              }
              label="Experience"
              // onBlur={handleBlur}
              // error={!!touched.contact && !!errors.contact}
              // helpertext={touched.contact && errors.contact}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1-7 Days">1-7 Days</MenuItem>
              <MenuItem value="14 Days">14 Days</MenuItem>
              <MenuItem value="30 Days">30 Days</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ minWidth: 120, gridColumn: "span 2" }}
          >
            {/* <Header
              title=""
              subtitle="How Many Years Of Experience Do You Have?"
            /> */}
            <InputLabel id="demo-simple-select-standard-label">
              What is your level of proficiency in English?
            </InputLabel>
            <Select
              variant="filled"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={initialValues.level}
              onChange={(e) =>
                setinitialValues({
                  ...initialValues,
                  level: e.target.value,
                })
              }
              label="Age"
              // onBlur={handleBlur}
              // error={!!touched.contact && !!errors.contact}
              // helpertext={touched.contact && errors.contact}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              <MenuItem value="Good">Good</MenuItem>
              <MenuItem value="Average">Average</MenuItem>
              <MenuItem value="Poor">Poor</MenuItem>
            </Select>
          </FormControl>
          {/* <TextField
            required
            fullWidth
            variant="filled"
            type="text"
            label="Specialization"
            // onBlur={handleBlur}
            onChange={(e) =>
              setinitialValues({
                ...initialValues,
                specialization: e.target.value,
              })
            }
            value={initialValues.specialization}
            name="Specialization"
            // error={!!touched.specialization && !!errors.specialization}
            // helpertext={touched.specialization && errors.specialization}
            sx={{ gridColumn: "span 2" }}
          /> */}
        </Box>
        <Box marginTop="3rem"></Box>

        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        ></Box>
        <Box marginTop="3rem">
          <Header title="Resume & Skills" subtitle="Upload Your Resume or CV" />
        </Box>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box
            variant="standard"
            sx={{
              minWidth: 120,
              gridColumn: "span 2",
              backgroundColor: "#323848",
              padding: "1rem",
              borderRadius: "3px",
              borderBottom: "1px solid #fff",
            }}
          >
            {initialValues?.file ? (
              <Box display="flex" gap={1} className="crsr">
                <span>{initialValues?.file?.name}</span>
                <CancelIcon onClick={handleRemove} />
              </Box>
            ) : (
              <input
                type="file"
                accept=".pdf, .doc, .docx"
                onChange={handleFileChange}
              />
            )}
          </Box>
          <Box
            variant="standard"
            sx={{
              minWidth: 120,
              gridColumn: "span 4",
              backgroundColor: "#323848",
              padding: "1rem",
              borderRadius: "3px",
              borderBottom: "1px solid #fff",
            }}
          >
            <TagsInput />
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          {loading ? (
            <Box display="flex" justifyContent="center" mt="1rem">
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleFormSubmit()}
              disabled={
                initialValues.fullName === "" ||
                initialValues.email === "" ||
                initialValues.contact === "" ||
                initialValues.country === "" ||
                initialValues.experience === "" ||
                initialValues.level === "" ||
                initialValues.file === null ||
                initialValues.position === "" ||
                initialValues.joiningtime === "" ||
                admindata?.developer?.tags?.length < 1
              }
            >
              Add Profile
            </Button>
          )}
        </Box>
      </form>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});

export default AddNewDeveloper;
