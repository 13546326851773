import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import { AddSkill } from "../../api/skills";
import Notification from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { Funciscallskill } from "../../reduxtoolkit/loginSlice";

export default function FormDialog() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const theme = useTheme();
  const adminData = useSelector((state) => state);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const colors = tokens(theme.palette.mode);
  const [skill, setskill] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddSkill = () => {
    setisLoading(true);
    AddSkill(skill)
      .then((res) => {
          setisLoading(false);
          handleClose();
        setNotify({
          isOpen: true,
          message: "Skill Added Successfully!",
          type: "success",
        });
        dispatch(Funciscallskill(true));
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message || err.response.data.error} `,
          type: "error",
        });
      });
  };

  return (
    <div>
      {/* <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ color: "#fff" }}
      > */}
      <Button
        variant="outlined"
        endIcon={<AddIcon />}
        sx={{
          color: "#fff !important",
          borderColor: "#fff",
          ":hover": {
            color: `${colors.greenAccent[400]} !important`,
            borderColor: `${colors.greenAccent[400]}`,
          },
        }}
        onClick={handleClickOpen}
      >
        Add&nbsp;Skill
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add&nbsp;Skill</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add skills to show on user's side!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Skill"
            type="text"
            fullWidth
            variant="standard"
            value={skill}
            onChange={(e) => setskill(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {" "}
            <span className="text-green-500">Cancel</span>
          </Button>
          <Button onClick={() => handleAddSkill()}>
            {" "}
            {isLoading ? (
              <span className="text-green-500">Adding...</span>
            ) : (
              <span className="text-green-500">Add</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
