import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockdata";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Funcauthentication } from "../../reduxtoolkit/loginSlice";
import {
  getAllDevelopers,
  getAllDevelopersFilter,
  updateStatus,
} from "../../api/developer";
import CircularProgress from "@mui/material/CircularProgress";
import NotFoundLotie from "../../helpers/notfoundloties";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Loader from "../../helpers/loader";
import Notification from "../../actions/alert";
import ScrollDialog from "./popup";
import {
  FuncCallapiDev,
  Funccallfilter,
  FuncDeleteDev,
} from "../../reduxtoolkit/developerSlice";
import DeletePopup from "./DeveloperDetails/deletepopup";
import FilterPopup from "./filterpopup";
import ClearIcon from "@mui/icons-material/Clear";

const AllDevelopers = () => {
  const developerData = useSelector((state) => state);
  console.log(developerData);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [AllDevelopersarr, setAllDevelopersarr] = useState([]);
  const [developerid, setdeveloperid] = useState("");
  const [statusloader, setstatusloader] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [showfilter, setshowfilter] = useState(false);
  const getDevelopers = async () => {
    setisLoading(true);
    setshowfilter(false);
    await getAllDevelopers()
      .then((res) => {
        console.log(res);
        setAllDevelopersarr(res.data.data.docs);
        setisLoading(false);
        dispatch(FuncCallapiDev(false));
      })
      .catch((err) => {
        console.log(err);
        setAllDevelopersarr([]);
        setisLoading(false);
      });
  };
  const getDevelopersfilter = async () => {
    setisLoading(true);
    setshowfilter(true);
    dispatch(Funccallfilter(false));

    await getAllDevelopersFilter(
      developerData?.developer?.filterdata?.skill,
      developerData?.developer?.filterdata?.experience
    )
      .then((res) => {
        console.log(res);
        setAllDevelopersarr(res.data.data.docs);
        setisLoading(false);
        dispatch(FuncCallapiDev(false));
      })
      .catch((err) => {
        console.log(err);
        setAllDevelopersarr([]);
        setisLoading(false);
      });
  };
  const getFullName = (params) => {
    return params.row.fullname || "";
  };
  const getEducations = (params) => {
    return `${params.row.qualification || ""} ${
      params.row.specialization || ""
    }`;
  };

  const UpdateStatus = async (value, id) => {
    setstatusloader(true);
    await updateStatus(value, id)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: "Status Updated Successfully!",
          type: "success",
        });
        setstatusloader(false);
        getDevelopers();
        console.log(res);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `${
            err.response?.data?.message || err.response?.data?.error
          }`,
          type: "error",
        });
        setstatusloader(false);

        console.log(err);
      });
  };
  const columns = [
    {
      field: "fullname",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: getFullName,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "skill",
      headerName: "Skills",
      headerAlign: "left",
      align: "left",
      width: "100px",
      flex: 1,
      renderCell: ({ row: { skill } }) => {
        return (
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              paddingRight: "10px",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            {skill?.map((skill) => (
              <Box
                marginRight={1}
                p="5px"
                display="flex"
                justifyContent="left"
                backgroundColor={colors.greenAccent[600]}
                borderRadius="4px"
                className="crsr"
              >
                {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}

                <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                  {skill?.value}&nbsp;({skill?.experience})
                </Typography>
              </Box>
            ))}
          </Box>
        );
      },
    },
    // {
    //   field: "qualification",
    //   headerName: "Qualification",
    //   flex: 1,
    //   valueGetter: getEducations,
    // },

    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { status, _id } }) => {
        return (
          <Box
            // backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            className="crsr"
            // onClick={() => window.open(resume, "_blank")}
          >
            {developerid === _id && statusloader ? (
              <Loader />
            ) : (
              <FormControl fullWidth>
                {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Status
              </InputLabel> */}
                <NativeSelect
                  sx={{
                    color: status === "verified" ? "green" : "red",
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setdeveloperid(_id);
                    UpdateStatus(e.target.value, _id);
                  }}
                  defaultValue={status}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value="verified">Approved</option>
                  <option value="unverified">Pending</option>
                </NativeSelect>
              </FormControl>
            )}
          </Box>
        );
      },
    },
    {
      field: "resume",
      headerName: "Resume",
      headerAlign: "let",
      align: "left",
      flex: 1,
      renderCell: ({ row: { resume } }) => {
        return (
          <Box
            // backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            className="crsr"
            onClick={() => window.open(resume, "_blank")}
          >
            {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}
            <Typography color={colors.grey[100]} sx={{ ml: "0px" }}>
              <PictureAsPdfIcon />{" "}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "note",
      headerName: "Note",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { notes, _id } }) => {
        return (
          <Box borderRadius="4px" className="crsr">
            {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}
            <ScrollDialog note={notes ? notes : ""} id={_id} />
          </Box>
        );
      },
    },
    {
      field: "",
      headerName: "Action",
      headerAlign: "center",
      align: "left",
      flex: 1,
      renderCell: ({ row: { _id } }) => {
        return (
          <Box
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="left"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            className="crsr"
            onClick={() => navigate(`/DeveloperDetails/${_id}`)}
          >
            {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              View&nbsp;Details
            </Typography>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(Funcauthentication(true));
    getDevelopers();
    setSelectedRows([]);
  }, [developerData?.developer?.callapi]);

  useEffect(() => {
    dispatch(Funcauthentication(true));
    developerData?.developer?.callfilter === true && getDevelopersfilter();
    setSelectedRows([]);
  }, [developerData?.developer?.callfilter]);

  const [selectedRows, setSelectedRows] = useState([]);
  console.log(selectedRows);
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Header title="Developers" subtitle="Managing the Developers" />
        </Box>
        <Box>
          <Button
            variant="outlined"
            endIcon={<AddIcon />}
            sx={{
              color: "#fff !important",
              borderColor: "#fff",
              ":hover": {
                color: `${colors.greenAccent[400]} !important`,
                borderColor: `${colors.greenAccent[400]}`,
              },
            }}
            onClick={() => navigate("/AddNewDeveloper")}
          >
            Add&nbsp;Developer
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {selectedRows?.length > 0 && (
          <Button
            sx={{
              color: "#fff !important",
              borderColor: "#fff",
              ":hover": {
                color: `${colors.redAccent[400]} !important`,
                borderColor: `${colors.redAccent[400]}`,
              },
            }}
            onClick={() =>
              dispatch(
                FuncDeleteDev({
                  id: selectedRows,
                })
              )
            }
          >
            {" "}
            <DeletePopup />
          </Button>
        )}
        {showfilter && (
          <Box paddingTop="1rem">
            <Typography
              sx={{
                color: "#fff !important",
                borderColor: "#fff",
                ":hover": {
                  color: `${colors.greenAccent[400]} !important`,
                  borderColor: `${colors.greenAccent[400]}`,
                },
                cursor: "pointer",
              }}
              onClick={() => getDevelopers()}
            >
              Clear&nbsp;Filter&nbsp;&nbsp;X
            </Typography>
          </Box>
        )}
        {AllDevelopersarr?.length > 0 && (
          <Button
            sx={{
              color: "#fff !important",
              borderColor: "#fff",
              ":hover": {
                color: `${colors.redAccent[400]} !important`,
                borderColor: `${colors.redAccent[400]}`,
              },
            }}
            onClick={() =>
              dispatch(
                FuncDeleteDev({
                  id: selectedRows,
                })
              )
            }
          >
            {" "}
            <FilterPopup />
          </Button>
        )}
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {console.log(AllDevelopersarr)}
        {
          //
          isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ verticalAlign: "middle" }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : AllDevelopersarr?.length > 0 ? (
            <DataGrid
              className="crsr"
              checkboxSelection
              loading={isLoading}
              rows={AllDevelopersarr && AllDevelopersarr}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row?._id}
              onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
              // paginationMode="server"
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ verticalAlign: "middle" }}
            >
              <Box>
                <Typography
                  variant="h2"
                  className="flex justify-center mb-[4rem]"
                >
                  No Developer Found!{" "}
                </Typography>
                {NotFoundLotie()}{" "}
              </Box>
            </Box>
          )
        }
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default AllDevelopers;
