import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useSelector, useDispatch } from "react-redux";
import { FuncSkills, FuncTags } from "../../reduxtoolkit/developerSlice";
import { useNavigate } from "react-router-dom";
import Notification from "../../actions/alert";
import { getAllSkills } from "../../api/skills";

import "./index.css";
let alltags = [];

const SelectSkills = () => {
  const navigate = useNavigate();
  const admindata = useSelector((state) => state);
  const isNonMobile = useMediaQuery("(min-width:800px)");
  const dispatch = useDispatch();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [searchdata, setsearchdata] = useState("");

  const handleFormSubmit = (values) => {
    dispatch(FuncSkills(tags));
    navigate("/HireDeveloper");
    console.log(values);
  };
  const [tags, setTags] = useState(["Github"]);
  console.log(tags);
  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    if (tags.indexOf(e.target.value) !== -1) {
      setNotify({
        isOpen: true,
        message: "Skill Already In Use!",
        type: "error",
      });
      e.target.value = "";
    } else {
      const value = e.target.value;
      if (!value.trim()) return;
      setTags([...tags, value]);
      e.target.value = "";
    }
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }
  const setdata = (value) => {
    console.log("setdata", value);
    setTags([...tags, value]);
    setsearchdata("");
  };
  const [skillsall, setskillsall] = useState([]);
  const getDevelopers = async () => {
    // setisLoading(true);
    await getAllSkills()
      .then((res) => {
        alltags = [];
        console.log(res);
        setskillsall(res?.data?.skills);
        res.data.skills.map((data) => alltags.push(data.skill[0]));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(alltags);
  console.log(skillsall);

  useEffect(() => {
    getDevelopers();
  }, []);
  return (
    <Box
      m="20px"
      height={admindata?.loginadmin?.isauthenticated ? "80vh" : "90vh"}
      overflow="auto"
      paddingRight="1rem"
    >
      <Header title="Find A Developer" subtitle="" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Box
          variant="standard"
          sx={{
            minWidth: 120,
            gridColumn: "span 4",
            backgroundColor: "#323848",
            padding: "1rem",
            borderRadius: "3px",
            borderBottom: "1px solid #fff",
          }}
        >
          <div>
            <div className="tags-input-container">
              {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{tag}</span>
                  <span className="close" onClick={() => removeTag(index)}>
                    &times;
                  </span>
                </div>
              ))}
              <input
                // onKeyDown={handleKeyDown}
                type="text"
                className="tags-input"
                placeholder="Add You Skills e.g React.js"
                value={searchdata}
                onChange={(e) => setsearchdata(e.target.value)}
              />
            </div>
            <Box mt={4} mb={3}>
              <Typography variant="h3">
                Popular Skills For <span>Developers</span>
              </Typography>
            </Box>
            <Box
              sx={{ backgroundColor: "#141B2D", padding: "2rem" }}
              className="tags-input-container"
            >
              {alltags
                ?.filter((data) => {
                  if (searchdata === "") {
                    return alltags;
                  } else {
                    return data?.toLowerCase().includes(searchdata);
                  }
                })
                .map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span
                      className="text crsr"
                      onClick={() => {
                        tags.indexOf(tag) !== -1
                          ? setNotify({
                              isOpen: true,
                              message: "Skill Already In Use!",
                              type: "error",
                            })
                          : setdata(tag);
                      }}
                    >
                      {tag}
                    </span>
                    {/* <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span> */}
                  </div>
                ))}
            </Box>
          </div>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button
          color="secondary"
          variant="contained"
          disabled={tags?.length < 1}
          onClick={() => handleFormSubmit()}
        >
          <span>Find Developer</span>
        </Button>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default SelectSkills;
