import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import CancelIcon from "@mui/icons-material/Cancel";
import TagsInput from "./tags";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { AddDeveloper } from "../../../api/developer";
import validator from "validator";
import Notification from "../../../actions/alert";
import { useNavigate } from "react-router-dom";
import { Funcauthentication } from "../../../reduxtoolkit/developerSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CountrySelect from "react-bootstrap-country-select";
import { countries } from "../../../helpers/countries";
import logo from "../../../assets/logo.png";
import Lottie from "react-lottie";
import notfound from "../../../assets/registration.json";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CreateDeveloperProfile = () => {
  const [selectedCountry, setselectedCountry] = useState(null);

  const notfoundOptions = {
    loop: true,
    autoplay: true,
    animationData: notfound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // console.log(selectedCountry);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admindata = useSelector((state) => state);
  // console.log("AddNewDeveloper", admindata);
  const isNonMobile = useMediaQuery("(min-width:800px)");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [formstate, setformstate] = useState("personal");

  useEffect(() => {
    dispatch(Funcauthentication(false));
  }, []);

  const [initialValues, setinitialValues] = useState({
    fullName: "",
    position: "",
    country: "none",
    email: "",
    contact: "",
    qualification: "",
    specialization: "",
    file: null,
    experience: "select",
    level: "select",
    joiningtime: "select",
  });

  const handleFormSubmit = () => {
    // e.preventDefault();
    setLoading(true);
    // console.log(initialValues);
    validator.isEmail(initialValues?.email) ? (
      AddDeveloper(initialValues, admindata?.developer?.tags)
        .then((res) => {
          // console.log(res);

          navigate("/Success");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setNotify({
            isOpen: true,
            message: `${
              err.response?.data?.message || err.response?.data?.error
            }`,
            type: "error",
          });
          setLoading(false);
        })
    ) : (
      <>
        {" "}
        {setNotify({
          isOpen: true,
          message: "Enter Valid Email!",
          type: "error",
        })}
        {setLoading(false)}
      </>
    );
  };

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };
  const [file, setFile] = useState(null);

  // useEffect(() => {
  //   dispatch(Funcauthentication(false));
  const handleFileChange = (event) => {
    // console.log(event.target.files[0]);
    setFile(event.target.files[0]);
    setinitialValues({
      ...initialValues,
      file: event.target.files[0],
    });
  };
  const handleRemove = () => {
    setinitialValues({
      ...initialValues,
      file: null,
    });
    setFile(null);
  };
  const [value, setValue] = useState();
  useEffect(() => {
    setinitialValues({
      ...initialValues,
      contact: value,
    });
  }, [value]);

  // const [value, setValue] = React.useState("fr");
  // console.log(value);
  const label = <small className="text-black">Country</small>;
  return (
    <Box
      m="20px"
      p="20px"
      height={admindata?.loginadmin?.isauthenticated ? "100vh" : "100vh"}
      overflow="auto"
      sx={{ backgroundColor: "#fff" }}
      // paddingRight="10rem"
      // paddingLeft="10rem"
      // paddingTop="2rem"
    >
      <Box>
        <img src={logo} alt="logo" width={180} height={180} />
        <div className="md:text-center text-left">
          <h3 className="text-textblue text-[2rem] mt-4">
            Apply to Join a Global Network of <br />
            World's Best and Brightest Software Developers
          </h3> 
          {/* <img src={logo} width={400} height={400} /> */}
        </div>
      </Box>
      <Box className="flex flex-col sm:flex-row justify-between gap-10 mt-[2rem] w-full">
        <Box className="sm:w-1/2	 md:px-[2rem] py-[2rem] bxshdw	">
          {formstate === "personal" ? (
            <Box>
              <form>
                <Header
                  title="Personal Information"
                  subtitle={
                    <span className="text-textblue  ">
                      Your Name & Contact Information
                    </span>
                  }
                />
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <small className="text-black mb-[-20px] text-[15px]">
                    Full&nbsp;Name<span className="text-red-700">*</span>
                  </small>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    type="text"
                    placeholder="Full Name"
                    // label="Full Name"
                    // onBlur={handleBlur}
                    onChange={(e) =>
                      setinitialValues({
                        ...initialValues,
                        fullName: e.target.value,
                      })
                    }
                    value={initialValues.fullName}
                    name="firstName"
                    // error={!!touched.firstName && !!errors.firstName}
                    // helpertext={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 4", marginBottom: "-20px" }}
                  />
                  <small className="text-black mb-[-20px] text-[15px]">
                    Email<span className="text-red-700">*</span>
                  </small>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    type="email"
                    placeholder="Email"
                    // onBlur={handleBlur}
                    onChange={(e) => {
                      setinitialValues({
                        ...initialValues,
                        email: e.target.value,
                      });
                    }}
                    value={initialValues.email}
                    name="email"
                    // error={!!touched.email && !!errors.email}
                    // helpertext={touched.email && errors.email}
                    sx={{ gridColumn: "span 4", marginBottom: "-20px" }}
                  />
                  <small className="text-black mb-[-20px] text-[15px]">
                    Country<span className="text-red-700">*</span>
                  </small>{" "}
                  <FormControl
                    variant="standard"
                    sx={{
                      minWidth: 120,
                      gridColumn: "span 4",
                      marginBottom: "-20px",
                      color: "#fff !important",
                    }}
                  >
                    {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}

                    <Select
                      variant="filled"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={initialValues.country || "Select Country"}
                      onChange={(e) =>
                        setinitialValues({
                          ...initialValues,
                          country: e.target.value,
                        })
                      }
                      label={label}
                      // onBlur={handleBlur}
                      // error={!!touched.contact && !!errors.contact}
                      // helpertext={touched.contact && errors.contact}
                    >
                      <MenuItem value="none">
                        <em>Select Country</em>
                      </MenuItem>

                      {countries?.map((country) => (
                        <MenuItem value={country.name}>{country.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box sx={{ gridColumn: "span 4", marginBottom: "0px" }}>
                    <small className="text-black mb-[-20px] text-[15px]">
                      Phone<span className="text-red-700">*</span>
                    </small>{" "}
                    <PhoneInput
                      placeholder="Enter phone number with country code"
                      value={value}
                      onChange={setValue}
                    />
                  </Box>
                </Box>

                <Box display="flex" justifyContent="end" mt="20px">
                  {/* {loading ? (
                    <Box display="flex" justifyContent="center" mt="1rem">
                      <CircularProgress color="secondary" />
                    </Box>
                  ) : ( */}
                  <Button
                    color="secondary"
                    className="bg-textblue"
                    variant="contained"
                    onClick={() => setformstate("proffesional")}
                    endIcon={<ArrowForwardIosIcon className="icnclr" />}
                    disabled={
                      initialValues.fullName === "" ||
                      initialValues.email === "" ||
                      initialValues.contact === "" ||
                      initialValues.country === "" ||
                      initialValues.country === "none"
                    }
                  >
                    <span className="icnclr text-base	">Next</span>
                  </Button>
                  {/* )} */}
                </Box>
              </form>
            </Box>
          ) : formstate === "proffesional" ? (
            <Box>
              <form>
                <Header title="Professional Information" subtitle="" />
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <small className="text-black mb-[-20px] text-[15px]">
                    Position&nbsp;Applying&nbsp;For
                    <span className="text-red-700">*</span>
                  </small>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    type="text"
                    placeholder="i.e (Front-End Developer)"
                    // onBlur={handleBlur}
                    onChange={(e) =>
                      setinitialValues({
                        ...initialValues,
                        position: e.target.value,
                      })
                    }
                    value={initialValues.position}
                    name="position"
                    // error={!!touched.lastName && !!errors.lastName}
                    // helpertext={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 4", marginBottom: "-10px" }}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      minWidth: 120,
                      gridColumn: "span 4",
                      marginBottom: "-10px",
                    }}
                  >
                    {/* <Header
              title=""
              subtitle="What is your level of proficiency in English"
            /> */}
                    <small className="text-black mb-[10px] text-[15px]">
                      How&nbsp;many&nbsp;years&nbsp;of&nbsp;experience&nbsp;do&nbsp;you&nbsp;have?
                      <span className="text-red-700">*</span>
                    </small>
                    {/* <InputLabel id="demo-simple-select-standard-label"></InputLabel> */}
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={initialValues.experience}
                      onChange={(e) =>
                        setinitialValues({
                          ...initialValues,
                          experience: e.target.value,
                        })
                      }
                      label="Experience"
                      // onBlur={handleBlur}
                      // error={!!touched.contact && !!errors.contact}
                      // helpertext={touched.contact && errors.contact}
                    >
                      <MenuItem value="select">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value="Less than 1 year">
                        Less than 1 year
                      </MenuItem>
                      <MenuItem value="1+ Year">1+ Year</MenuItem>
                      <MenuItem value="2+ Year">2+ Year</MenuItem>
                      <MenuItem value="3+ Year">3+ Year</MenuItem>
                      <MenuItem value="4+ Year">4+ Year</MenuItem>
                      <MenuItem value="5+ Year">5+ Year</MenuItem>
                      <MenuItem value="6+ Year">6+ Year</MenuItem>
                      <MenuItem value="7+ Year">7+ Year</MenuItem>
                      <MenuItem value="8+ Year">8+ Year</MenuItem>
                      <MenuItem value="9+ Year">9+ Year</MenuItem>
                      <MenuItem value="10+ Year">10+ Year</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box marginTop="2rem"></Box>

                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 120, gridColumn: "span 4" }}
                  >
                    <small className="text-black mb-[10px] text-[15px]">
                      How&nbsp;soon&nbsp;can&nbsp;you&nbsp;join&nbsp;us&nbsp;upon&nbsp;selection?
                      <span className="text-red-700">*</span>
                    </small>
                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={initialValues.joiningtime}
                      onChange={(e) =>
                        setinitialValues({
                          ...initialValues,
                          joiningtime: e.target.value,
                        })
                      }
                      label="Experience"
                    >
                      <MenuItem value="select">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value="1-7 Days">1-7 Days</MenuItem>
                      <MenuItem value="14 Days">14 Days</MenuItem>
                      <MenuItem value="30 Days">30 Days</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 120, gridColumn: "span 4" }}
                  >
                    <small className="text-black mb-[10px] text-[15px]">
                      What&nbsp;is&nbsp;your&nbsp;level&nbsp;of&nbsp;proficiency&nbsp;in&nbsp;English?
                      <span className="text-red-700">*</span>
                    </small>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={initialValues.level}
                      onChange={(e) =>
                        setinitialValues({
                          ...initialValues,
                          level: e.target.value,
                        })
                      }
                      label="Level"
                      // onBlur={handleBlur}
                      // error={!!touched.contact && !!errors.contact}
                      // helpertext={touched.contact && errors.contact}
                    >
                      <MenuItem value="select">
                        <em>Select</em>
                      </MenuItem>

                      <MenuItem value="Good">Good</MenuItem>
                      <MenuItem value="Average">Average</MenuItem>
                      <MenuItem value="Poor">Poor</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* <Box marginTop="3rem"></Box> */}

                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                ></Box>

                <Box display="flex" justifyContent="end" mt="20px" gap={5}>
                  {/* {loading ? (
                    <Box display="flex" justifyContent="center" mt="1rem">
                      <CircularProgress color="secondary" />
                    </Box>
                  ) : ( */}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setformstate("personal")}
                    startIcon={<ArrowBackIosIcon className="icnclr" />}

                    // disabled={
                    //   initialValues.fullName === "" ||
                    //   initialValues.email === "" ||
                    //   initialValues.contact === "" ||
                    //   initialValues.country === "" ||
                    //   initialValues.experience === "" ||
                    //   initialValues.level === "" ||
                    //   initialValues.file === null ||
                    //   initialValues.position === "" ||
                    //   initialValues.joiningtime === "" ||
                    //   admindata?.developer?.tags?.length < 1
                    // }
                  >
                    <span className="icnclr text-base	">Back</span>{" "}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setformstate("resume")}
                    endIcon={<ArrowForwardIosIcon className="icnclr" />}
                    disabled={
                      initialValues.experience === "select" ||
                      initialValues.level === "select" ||
                      initialValues.position === "" ||
                      initialValues.joiningtime === "select"
                    }
                  >
                    <span className="icnclr text-base	">Next</span>
                  </Button>

                  {/* )} */}
                </Box>
              </form>
            </Box>
          ) : (
            <Box>
              <form>
                {/* <Header
                title="Personal Information"
                subtitle="Your Name & Contact Information"
              /> */}

                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                ></Box>
                <Box>
                  <Header
                    title="Resume & Skills"
                    subtitle="Upload Your Resume or CV"
                  />
                </Box>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box
                    variant="standard"
                    sx={{
                      minWidth: 120,
                      gridColumn: "span 2",
                      backgroundColor: "#323848",
                      padding: "1rem",
                      borderRadius: "3px",
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    {initialValues?.file ? (
                      <Box display="flex" gap={1} className="crsr">
                        <span>{initialValues?.file?.name}</span>
                        <CancelIcon onClick={handleRemove} />
                      </Box>
                    ) : (
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={handleFileChange}
                      />
                    )}
                  </Box>
                  <Box
                    variant="standard"
                    sx={{
                      minWidth: 120,
                      gridColumn: "span 4",
                      backgroundColor: "#323848",
                      padding: "1rem",
                      borderRadius: "3px",
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    <TagsInput />
                  </Box>
                </Box>

                {loading ? (
                  <Box display="flex" justifyContent="end" mt="1rem">
                    <CircularProgress color="secondary" />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="end" mt="20px" gap={5}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => setformstate("proffesional")}
                      startIcon={<ArrowBackIosIcon className="icnclr" />}
                    >
                      <span className="icnclr text-base	">Back</span>
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleFormSubmit()}
                      disabled={
                        initialValues.fullName === "" ||
                        initialValues.email === "" ||
                        initialValues.contact === "" ||
                        initialValues.country === "select" ||
                        initialValues.experience === "select" ||
                        initialValues.level === "select" ||
                        initialValues.file === null ||
                        initialValues.position === "" ||
                        initialValues.joiningtime === "select" ||
                        admindata?.developer?.tags?.length < 1
                      }
                    >
                      Submit{" "}
                    </Button>
                  </Box>
                )}

                {/* // )} */}
              </form>
            </Box>
          )}
        </Box>

        <Box className="w-1/2 	">
          <Lottie
            options={notfoundOptions}
            width={250}
            height={550}
            //   backgroundColor={"grey"}
          />
        </Box>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});

export default CreateDeveloperProfile;
