import React from "react";
 import { Alert, Snackbar } from "@mui/material";

export default function Notification(props) {
  const { notify, setNotify } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  return (
    <Snackbar
      className={
        notify.type == "error" ? "messagetoltip" : "messagetoltipsuccess"
      }
      open={notify.isOpen}
      autoHideDuration={1000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert severity={notify.type} onClose={handleClose} className="bgalert">
        {notify.message}
      </Alert>
    </Snackbar>
  );
}
