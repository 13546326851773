import React from "react";
import Lottie from "react-lottie";
import notfound from "../../assets/Successful.json";
import { Box, Typography, useTheme } from "@mui/material";

function SuccessPageUpdate() {
  const notfoundOptions = {
    loop: true,
    autoplay: true,
    animationData: notfound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginTop="5rem"
    >
      <Lottie
        options={notfoundOptions}
        width={250}
        height={250}
        //   backgroundColor={"grey"}
      />
      <Typography variant="h2" className="flex justify-center mb-[4rem]">
        Developer Profile Updated Successfully{" "}
      </Typography>
    </Box>
  );
}

export default SuccessPageUpdate;
