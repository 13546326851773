import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import bgimage from "../../assets/loginbg.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Funcauthentication,
  FuncisAuthorized,
  FuncToken,
} from "../../reduxtoolkit/loginSlice";
import { LoginApi } from "../../api/authsection";
import Notification from "../../actions/alert";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoginScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [emailmessage, setemailmessage] = useState("");
  const [passwordmessage, setpasswordmessage] = useState("");
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    formData?.email === ""
      ? setNotify({
          isOpen: true,
          message: "Enter Your Email!",
          type: "error",
        })
      : formData?.password === ""
      ? setNotify({
          isOpen: true,
          message: "Enter Your Password!",
          type: "error",
        })
      : ApiCall();
  };

  const ApiCall = async () => {
    setLoading(true);
    await LoginApi(formData)
      .then((res) => {
        console.log(res);
        setNotify({
          isOpen: true,
          message: "Login Successfully!",
          type: "success",
        });
        sessionStorage.setItem("token", res.headers.token);
        dispatch(FuncToken(res.headers.token));
        dispatch(Funcauthentication(true));
        dispatch(FuncisAuthorized(true));

        navigate("/Developers");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.data.error || err.response.data.message} `,
          type: "error",
        });
      });
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(Funcauthentication(false));
  }, []);

  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgimage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          alignItems="center"
        >
          <Box
            sx={{
              my: 18,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setemailmessage("");
                  setformData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
              />
              <span className="text-red-600	">{emailmessage}</span>
              <FormControl
                sx={{ mt: "1rem", width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    setpasswordmessage("");
                    setformData({
                      ...formData,
                      password: e.target.value,
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                <span className="text-red-600	">{passwordmessage}</span>
              </FormControl>

              {loading ? (
                <Box display="flex" justifyContent="center" mt="1rem">
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Button
                  onClick={() => handleSubmit()}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: `${colors.greenAccent[700]}`,
                  }}
                >
                  Sign In
                </Button>
              )}
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2"></Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" color="#fff">
                    <span className="" onClick={() => navigate("/VerifyEmail")}>
                      Forgot password?
                    </span>
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
