import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockdata";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Funcauthentication,
  Funciscallskill,
  Funcskillid,
} from "../../reduxtoolkit/loginSlice";
import { getAllDevelopers, updateStatus } from "../../api/developer";
import CircularProgress from "@mui/material/CircularProgress";
import NotFoundLotie from "../../helpers/notfoundloties";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Loader from "../../helpers/loader";
import Notification from "../../actions/alert";
import ScrollDialog from "./popup";
import { FuncCallapiDev } from "../../reduxtoolkit/developerSlice";
import { getAllSkills } from "../../api/skills";
import DeletePopup from "./popup";
import FormDialog from "./AddSkill";

const AllSKills = () => {
  const developerData = useSelector((state) => state);
  console.log(developerData);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [AllDevelopersarr, setAllDevelopersarr] = useState([]);
  const [developerid, setdeveloperid] = useState("");
  const [statusloader, setstatusloader] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const getDevelopers = async () => {
    setisLoading(true);
    await getAllSkills()
      .then((res) => {
        console.log(res);
        setAllDevelopersarr(res.data.skills);
        setisLoading(false);
        dispatch(Funciscallskill(false));
      })
      .catch((err) => {
        console.log(err);
        setAllDevelopersarr([]);
        setisLoading(false);
      });
  };
  const getFullName = (params) => {
    return params.row.fullname || "";
  };
  const getEducations = (params) => {
    return `${params.row.qualification || ""} ${
      params.row.specialization || ""
    }`;
  };

  const UpdateStatus = async (value, id) => {
    setstatusloader(true);
    await updateStatus(value, id)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: "Status Updated Successfully!",
          type: "success",
        });
        setstatusloader(false);
        getDevelopers();
        console.log(res);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `${
            err.response?.data?.message || err.response?.data?.error
          }`,
          type: "error",
        });
        setstatusloader(false);

        console.log(err);
      });
  };
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
      headerAlign: "left",
      align: "left",
      //   valueGetter: getFullName,
    },

    {
      field: "skill",
      headerName: "Skills",
      headerAlign: "left",
      align: "left",

      flex: 1,
      renderCell: ({ row: { skill } }) => {
        return (
          <Box
            sx={{
              overflow: "auto",
              display: "flex",
              paddingRight: "10px",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            {skill?.map((skill) => (
              <Box
                marginRight={1}
                p="5px"
                display="flex"
                justifyContent="left"
                backgroundColor={colors.greenAccent[600]}
                borderRadius="4px"
                className="crsr"
              >
                {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}

                <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                  {skill}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      },
    },

    {
      field: "",
      headerName: "Action",
      headerAlign: "center",
      align: "left",
      flex: 1,
      renderCell: ({ row: { _id } }) => {
        return (
          <Box
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="left"
            // backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            className="crsr"
            onClick={() => dispatch(Funcskillid(_id))}
          >
            {/* {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />} */}
            <Typography sx={{ ml: "5px" }}>
              <DeletePopup />
              {/* Delete */}
            </Typography>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    // dispatch(Funcauthentication(true));
    getDevelopers();
  }, [developerData?.loginadmin?.iscallskill]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Header title="Skills" subtitle="Managing the Skills" />
        </Box>
        <Box>
          {/* <Button
            variant="outlined"
            endIcon={<AddIcon />}
            sx={{
              color: "#fff !important",
              borderColor: "#fff",
              ":hover": {
                color: `${colors.greenAccent[400]} !important`,
                borderColor: `${colors.greenAccent[400]}`,
              },
            }}
            onClick={() => navigate("/AddNewDeveloper")}
          > */}
          <FormDialog />
          {/* </Button> */}
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {console.log(AllDevelopersarr)}
        {
          //
          isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              sx={{ verticalAlign: "middle" }}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : AllDevelopersarr?.length > 0 ? (
            <DataGrid
              className="crsr"
              //   checkboxSelection
              loading={isLoading}
              rows={AllDevelopersarr && AllDevelopersarr}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row?._id}
              // paginationMode="server"
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ verticalAlign: "middle" }}
            >
              <Box>
                <Typography
                  variant="h2"
                  className="flex justify-center mb-[4rem]"
                >
                  No Skills Found!{" "}
                </Typography>
                {NotFoundLotie()}{" "}
              </Box>
            </Box>
          )
        }
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default AllSKills;
