import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getAllskilledDevelopers, sendMail } from "../../api/developer";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { getAllClients, getAllClientsdetails } from "../../api/clients";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Funcauthentication } from "../../reduxtoolkit/developerSlice";

const ClientsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admindata = useSelector((state) => state);
  const isNonMobile = useMediaQuery("(min-width:800px)");
  const [selectedid, setselectedid] = useState("");
  const [loading, setloading] = useState(false);

  const [isLoading, setisLoading] = useState(false);
  const [developersfiltered, setdevelopersfiltered] = useState([]);
  const getDevelopers = async () => {
    setisLoading(true);
    await getAllClientsdetails(id)
      .then((res) => {
        console.log(res);
        setdevelopersfiltered(res.data?.data?.docs?.Developers);
        setisLoading(false);
        console.log(res);
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getDevelopers();
   dispatch(Funcauthentication(false))

  }, []);

  return (
    <Box
      m="20px"
      height={admindata?.loginadmin?.isauthenticated ? "80vh" : "90vh"}
      overflow="auto"
      paddingRight="1rem"
    >
      <Box>
        <KeyboardBackspaceIcon
          color="#fff"
          fontSize="large"
          onClick={() => navigate("/Clients")}
          className="crsr"
        />
      </Box>
      <Header title="Developers" subtitle="Invited Developers" />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            verticalAlign: "middle",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          display="flex"
          flexWrap="wrap"
          gap={5}
          m="20px"
          height="58vh"
          overflow="auto"
          pb="2rem"
        >
          {developersfiltered
            // .filter((data) => data._id === id)
            .map((data, index) => (
              <Card
                key={index}
                className={`${selectedid === data?._id ? "selected" : ""}`}
                variant="outlined"
                sx={{
                  backgroundColor: "#fff",
                  color: `${selectedid === data?._id ? "#fff" : "#222"}`,
                  width: "250px",
                  backgroundColor: `${
                    selectedid === data?._id ? "#1F2A40" : "#fff"
                  }`,
                  boxShadow: `${
                    selectedid === data?._id
                      ? " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, #3da58a 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
                      : "none"
                  }`,
                  height: "250px",
                }}
              >
                {" "}
                <React.Fragment>
                  <CardContent
                    //   onClick={() => setselectedid(data?._id)}
                    sx={{ cursor: "pointer" }}
                    key={index}
                  >
                    <Typography variant="h4" component="div">
                      {data?.fullname}{" "}
                    </Typography>
                    <Typography
                      sx={{ mb: 1 }}
                      // color={selectedid === data?._id ? "#fff" : "#222"}
                    >
                      {data?.email}{" "}
                    </Typography>
                    <Typography variant="h4" component="div" sx={{ mb: 1 }}>
                      {`${data?.qualification} (${data.specialization})`}{" "}
                    </Typography>
                    <Box display="flex" gap={1} flexWrap="wrap">
                      {data?.skill?.map((skil) => (
                        <Typography
                          key={data}
                          variant="body2"
                          sx={{
                            backgroundColor: "#1e5245",
                            color: "#fff",
                            borderRadius: "10px",
                            padding: "8px 10px",
                          }}
                        >
                          {skil.value}
                        </Typography>
                      ))}
                    </Box>
                    <Typography
                      sx={{ mb: 1.5, mt: 1.5 }}
                      // color={selectedid === data?._id ? "#fff" : "#222"}
                    >
                      <span className="text-green-500">Contact:</span>&nbsp;
                      {data?.phone}{" "}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="large"
                      onClick={() => window.open(data?.resume, "_blank")}
                    >
                      <span
                        className={
                          selectedid === data?._id
                            ? "  font-bold	text-green-500	"
                            : "text-dark font-bold text-green-500		"
                        }
                      >
                        Show&nbsp;CV
                      </span>
                    </Button>
                  </CardActions>
                </React.Fragment>
              </Card>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default ClientsDetails;
