import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { updateNote } from "../../api/developer";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FuncCallapiDev } from "../../reduxtoolkit/developerSlice";
import Notification from "../../actions/alert";
import moment from "moment";

export default function ScrollDialog({ note, id }) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // console.log(note, id);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [notetext, setnotetext] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const AddNote = async () => {
    setisLoading(true);

    updateNote(notetext, id)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: "Note Added Successfully!",
          type: "success",
        });
        // console.log(res);
        setisLoading(false);
        handleClose();
        setTimeout(() => {
          dispatch(FuncCallapiDev(true));
        }, 1000);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `${
            err.response?.data?.message || err.response?.data?.error
          }`,
          type: "error",
        });
        // console.log(err);
        setisLoading(false);
      });
  };
  return (
    <div>
      <Button onClick={handleClickOpen("paper")} sx={{ color: "#fff" }}>
        <SpeakerNotesIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Note</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            className="text-white"
          >
            {note?.map((note) => (
              <div className="bg-backgroundColor rounded mb-2">
                <div
                  className="p-1 text-green-500"
                  dangerouslySetInnerHTML={{
                    __html: note && note?.note,
                  }}
                />
                <div className="text-right pb-1 pr-2">{moment(note?.createdAt).fromNow()}</div>
              </div>
            ))}

            {/* <TextField
              className="px-[10px]"
              autoFocus
              margin="dense"
              id="name"
              label="Add Note"
              type="text"
              fullWidth
              variant="standard"
              multiline
              onChange={(e) => {
                setnotetext(e.target.value);
              }}
            /> */}
            <ReactQuill
              theme="snow"
              value={notetext}
              onChange={(e) => {
                // console.log(e);
                setnotetext(e);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span className="text-white">Cancel</span>
          </Button>
          <Button
            onClick={() => {
              AddNote();
            }}
            className="text-white"
            disabled={notetext === ""}
          >
            <span className={notetext === "" ? "text-grey" : "popuptext"}>
              {isLoading ? "Adding Note..." : "Add Note"}
            </span>
          </Button>
        </DialogActions>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
