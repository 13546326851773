import { createSlice } from "@reduxjs/toolkit";

const developerSlice = createSlice({
  name: "developer",
  initialState: {
    isauthenticated: true,
    tags: ["Github"],
    selectedSkills: [],
    deletedata: {},
    callapi: false,
    filterdata: {},
    callfilter: false,
  },
  reducers: {
    Funccallfilter: (state, action) => {
      state.callfilter = action.payload;
      // console.log(action.payload);
    },
    Funcfilterdata: (state, action) => {
      state.filterdata = action.payload;
      // console.log(action.payload);
    },
    Funcauthentication: (state, action) => {
      state.isauthenticated = action.payload;
      // console.log(action.payload);
    },
    FuncTags: (state, action) => {
      state.tags = action.payload;
      // console.log(action.payload);
    },
    FuncSkills: (state, action) => {
      state.selectedSkills = action.payload;
      // console.log(action.payload);
    },
    FuncDeleteDev: (state, action) => {
      state.deletedata = action.payload;
      // console.log(action.payload);
    },
    FuncCallapiDev: (state, action) => {
      state.callapi = action.payload;
      // console.log(action.payload);
    },
  },
});
export const {
  Funcauthentication,
  FuncTags,
  FuncSkills,
  FuncDeleteDev,
  FuncCallapiDev,
  Funcfilterdata,
  Funccallfilter,
} = developerSlice.actions;

export default developerSlice.reducer;
