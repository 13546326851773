import React from "react";
import Lottie from "react-lottie";
import notfound from "../../assets/Successful.json";
import { Box, Typography, useTheme } from "@mui/material";

function SuccessPage() {
  const notfoundOptions = {
    loop: true,
    autoplay: true,
    animationData: notfound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
       paddingBottom="2rem"
     className="md:mt-[5rem]"
    >
      <Lottie
        options={notfoundOptions}
        width={250}
        height={250}
        //   backgroundColor={"grey"}
      />
      <Typography
        variant="h2"
        className="flex justify-center mb-[4rem] text-colorgreen text-center md:px-[15rem] px-5 overflow-auto pb-5"
      >
        Thank you for submitting your job application to Moldech. We will be
        contacting you soon to discuss the next steps in the hiring process.
        Thank you for your interest in joining our team.{" "}
      </Typography>
    </Box>
  );
}

export default SuccessPage;
