import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    // isauthenticated: true,
    // tags: ["Github"],
    // selectedSkills: [],
    // deletedata: {},
    callapi: false,
  },
  reducers: {
    // Funcauthentication: (state, action) => {
    //   state.isauthenticated = action.payload;
    //   // console.log(action.payload);
    // },
    // FuncTags: (state, action) => {
    //   state.tags = action.payload;
    //   // console.log(action.payload);
    // },
    // FuncSkills: (state, action) => {
    //   state.selectedSkills = action.payload;
    //   // console.log(action.payload);
    // },
    // FuncDeleteDev: (state, action) => {
    //   state.deletedata = action.payload;
    //   // console.log(action.payload);
    // },
    FuncCallapiDev: (state, action) => {
      state.callapi = action.payload;
      // console.log(action.payload);
    },
  },
});
export const {
  //   Funcauthentication,
  //   FuncTags,
  //   FuncSkills,
  //   FuncDeleteDev,
  FuncCallapiDev,
} = clientSlice.actions;

export default clientSlice.reducer;
