import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import { reducer } from "./slice";
import loginSlice from "./loginSlice";

import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import developerSlice from "./developerSlice";
import clientSlice from "./clientSlice";

const rootpersistConfig = {
  key: "root",
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};
const adminpersistConfig = {
  key: "loginadmin",
  version: 1,
  storage,
  // stateReconciler: autoMergeLevel2,
  // blacklist: ["loading", "isSuccess"],
};
const developerpersistConfig = {
  key: "developers",
  version: 1,
  storage,
  // stateReconciler: autoMergeLevel2,
  // blacklist: ["developer"],
};
const clientpersistConfig = {
  key: "clients",
  version: 1,
  storage,
  // stateReconciler: autoMergeLevel2,
  // blacklist: ["developer"],
};

const rootReducer = combineReducers({
  //   admin: rootSlice,
  loginadmin: persistReducer(adminpersistConfig, loginSlice),
  developer: persistReducer(developerpersistConfig, developerSlice),
  client: persistReducer(clientpersistConfig, clientSlice),
});
const persistedReducer = persistReducer(rootpersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],

  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
});
export const persistor = persistStore(store);
